
<app-navbar></app-navbar>
<div class="row mainrow_margin">
   <div class="col-md-2" *ngIf="colmd2">
    <app-sidenav></app-sidenav>
   </div>
   <div class="section_one" [ngClass]="{'col-md-10': !colmd12,'col-md-12': colmd12}" >
    <h4 [ngStyle]="{'margin-left': colmd12 == true ? '110px' : '15px' }">Appointment Details</h4>
    <br>
    <div class="row section_two">
     <div class="col-md-3">
      <h4  [ngStyle]="{'margin-left': colmd12 == true ? '65px' : '-30px' }">Scheduled</h4>
     </div>
     <div class="col-md-3">
      <button type="button" class="btn btn-secondary Walk-In" data-toggle="modal" data-target="#demo-4">Walk-In</button>
    </div>
    <div class="col-md-3">
      <button type="button" class="btn btn-secondary NC-Inspection" data-toggle="modal" data-target="#demo-6">NC-Inspection</button>

        </div>
        <div class="col-md-3">
          <button type="button" *ngIf="appointmentFilter2 == 'show'" class="btn btn-filter" data-toggle="modal" data-target="#demo-7">Filter <i class="fa fa-filter" aria-hidden="true"></i></button>
            </div>

  </div>
  <div class="row" style="margin-top:1%;margin-left: 28px;margin-bottom: 1%;">
    <div class="col-md-3">

    </div>
    <div class="col-md-3">

   </div>
   <div class="col-md-3">


       </div>
       <div class="col-md-3">
        <button type="button"  (click)= AppoitnmentsfromFilter() *ngIf="appointmentFilter1 == 'show'" class="btn " style="width: 150px;border:#211e50;margin-left: 47px;  width: 150px;
        border: 1px solid #6f09e8;
        color: #343a40;margin-bottom: 10px;" >Clear Filter <i style="color: #6f09e8;
    font-size: 20px;
    margin-left: 5px;" class="fa fa-filter" aria-hidden="true"></i></button>
           </div>

 </div>





  <div class="container">
    <div class="table-responsive">
      <table class="table table-striped table-bordered" id="example"  *ngIf="showTable" datatable [dtOptions]="dtOptions"  style="min-width:1076.66px;font-size: 13.5px;">
        <thead class="thead-dark">
            <tr>
             <th>Ticket Id</th>
            <th>First<br/>Name</th>
            <th>Last<br/>Name</th>
            <th>Phone<br/>Number</th>
            <th style="width: 65px;">Service<br>Date</th>
            <th>Services<br/>Requested</th>
            <th>Additional<br/>Services</th>
            <th>Make</th>
            <th>Model</th>
            <!--<th>Year</th>-->
            <th>Status</th>
            <th>Details</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor= "let data of getallactiveappointmentsdata">
        <td>{{data.appointmentId}}</td>
         <td>{{data.firstName}}</td>
         <td>{{data.lastName}}</td>
         <td style="width: 150.75px; padding-right: 0px;text-align: center; padding-left: 0px;">{{data.phoneNo.substr(0, 3) + '-' + data.phoneNo.substr(3, 3) + '-' + data.phoneNo.substr(6, 4)}}</td>
         <!-- <td>{{datas.email}}</td> -->
         <td>{{data.dropOffDate | date:'MM-dd-yyyy'}}</td>
         <!-- <td>{{datas.preferredContact}}</td> -->
         <td>{{data.serviceRequested}}</td>
         <td>{{data.additionalServices}}</td>
         <td>{{data.vechileBrand}}</td>
         <td>{{data.vechileModel}}</td>
         <!--<td>{{data.vechileManufacturingYear}}</td>-->
         <td>{{VechileStatus(data.status)}}</td>
         <td><button class="btn btn-primary"  data-toggle="modal" data-target=".bd-example-modal-lg1" (click)="GetAppointmentById(data.appointmentId)" style="    width: 35px;
          padding: 5px;"><i class="fa fa-edit"></i> </button>
          <button class="btn btn-primary"  data-toggle="modal" data-target=".bd-example-modal-lg5" (click)="GetAppointmentviewById(data.appointmentId)" style="    width: 35px;
          padding: 5px;margin-left:2px;"><i class="fa fa-eye" aria-hidden="true"></i></button></td>
         <!-- <td>{{datas.status}}</td> -->
        </tr>
        </tbody>
    </table>

    </div>
  </div>
 <div>
  <div class="container">


    <!-- Modal -->

    <!-- [ Modal #2 ] -->
    <div class="modal fade" id="demo-2" tabindex="-1">
      <div class="modal-dialog modal-lg" style="margin-left: 250px;">
       <div class="modal-content" style="background-image: url(../../../assets/images/customer_pop2.png);min-height: 250px;">
         <img src="../../../assets/images/customer_pop1.png" alt="pop_animation" class="pop_animation_customer">
         <div class="row">
          <div class="col-md-12" style="padding-top: 15px;
          padding-bottom: 15px;
          padding-left: 32px;">
            <h4 style="text-align: center;font-family: 'Calistoga', cursive;">Customer Information<br>
             <img src="../../../assets/images/customer_underline.png" alt="customer_heading" class="customer_image_underline">
            </h4>

            <button type="button" class="btn btn-danger" data-dismiss="modal"  style="position: absolute;
            right: 24px;border-radius: 50%;
            top: 5px;">&times;</button>
            <button type="button" class="close" data-dismiss="modal"><i class="icon-xs-o-md"></i></button>
          </div>
         </div>
            <div>
              <div class="container">
                <table cellpadding="3" cellspacing="0" border="0" style="width: 100%; margin: 0 auto 2em auto;">
                  <tbody class="row">
                      <tr id="filter_col0" data-column="0" class="col-md-4">
                        <label>First Name </label>
                        <input type="text" class="column_filter form-control" id="col0_filter">

                      </tr>
                      <tr id="filter_col1" data-column="1" class="col-md-4">
                        <label>Last Name </label>
                        <input type="text" class="column_filter form-control" id="col1_filter">
                            </tr>
                      <tr id="filter_col2" data-column="2" class="col-md-4">
                        <label>Phone Number </label>
                        <input type="text" class="column_filter form-control" id="col2_filter">
                            </tr>
                  </tbody>
              </table>
              <div>


              <div class="table-responsive" id="showDiv" style="width: 100%;height:300px;display:none;margin-bottom:75px;">
                  <table class="table table-striped table-bordered" id="examplepopup" *ngIf="showTable" style="width:100%;">
                      <thead>
                          <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Phone Number</th>
                          <th>Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor= "let completeds of datacompleted">

                       <td>{{completeds.firstName}}</td>
                       <td>{{completeds.lastName}}</td>
                       <td>{{completeds.phoneNo}}</td>
                       <td>
                        <button type="button" class="btn btn-primary text-white text-center" data-toggle="modal"
                        data-target="#demo-4" data-dismiss="modal" (click)="GetAppointmentByIdExsting(completeds.appointmentId)">Select</button>
                    </td>

                      </tr>
                      </tbody>
                  </table>

              </div>
              </div>
            </div>
       </div>
      </div>
    </div>

 </div>
<!-- existbutton walkin form model -->
 <!-- [ Modal #1 ] -->
 <div class="modal fade" id="demo-4" tabindex="-1">
  <div class="modal-dialog modal-xl appointment_popup_main" style="margin-left:28%; margin-right: 20%; margin-top: 10%;">
    <div class="modal-content" style="padding-left:15px;padding-right:15px;">
      <h4 class="modal-title col-md-9 text-danger" id="exampleModalLabel" style="margin-bottom:15px;
      margin-top:15px;
      margin-left: 0px;"> Add Appointment</h4>
      <button type="button" (click)="closepopup()" class="btn btn-danger" data-dismiss="modal"   style="position: absolute;
      right: 4px;border-radius: 50%;
      top: 4px;">&times;</button>
      <button type="button" class="close"   data-dismiss="modal"><i class="icon-xs-o-md"></i></button>
      <button type="button" (click)="getallData()" class="btn btn-danger text-white text-center" data-toggle="modal"
      data-target="#demo-2" data-dismiss="modal" style="width: 170px;
      position: absolute;
      right: 100px;
      top: 15px;">Existing Customer</button>
      <div class="modal-body">
        <section class="wizard-section">
          <div class="row no-gutters">
            <div class="col-lg-12 col-md-12">
              <div class="form-wizard">
                <form  role="form" [formGroup]="walkinAddForm" (ngSubmit)="WalkinAdd()">
                  <div class="form-wizard-header">
                    <ul class="list-unstyled form-wizard-steps clearfix" id="wizard" style="margin-left: 13%;">
                      <li class="active">
                        <span>1</span><br>
                        <p>Customer Information</p>
                      </li>
                      <li><span>2</span><br>
                        <p>Service Information</p>
                      </li>
                      <li><span>3</span><br>
                        <p>Technician  Information</p>
                      </li>
                    </ul>
                  </div>
                  <fieldset class="wizard-fieldset show">
                    <div>
                      <div class="row">
                        <div class="col-md-6 form-group">
                            <label class="form_row_heading">Customer Details</label>
                        </div>
                    </div>

                    <div class="row" style="margin-top: -15px">
                      <div class="col-md-3 form-group">
                        <label>First Name <span class="text-danger">*</span></label>
                          <input type="text" class="form-control wizard-required" id="txtfName"  formControlName="firstName" placeholder="First Name" name="txtfName" aria-controls="example" required>
                          <!-- <span style="font-weight: bold;color: red;font-size: 14PX;"  *ngIf="!walkinAddForm.controls['firstName'].valid&&walkinAddForm.controls['firstName'].dirty">
                              <span *ngIf="walkinAddForm.controls.firstName.errors.required">First Name is Required.</span>
                              <span *ngIf="walkinAddForm.controls.firstName.errors.minlength">User name minimum should be length of 3.</span>
                              <span *ngIf="walkinAddForm.controls.firstName.errors.maxlength">User name maximum should be length of 50.</span>
                            </span> -->
                            <div class="wizard-form-error">First Name is Required</div>
                      </div>

                      <div class="col-md-3 form-group">
                        <label>Last Name <span class="text-danger">*</span></label>
                          <input type="text" class="form-control wizard-required" id="txtlName"  formControlName="lastName" placeholder="Last Name" name="txtlName">
                          <div class="wizard-form-error">Last Name is Required</div>
                      </div>







                      <div class="col-md-3 form-group">

                        <label>Mobile Number <span class="text-danger">*</span></label>
                          <input type="text" class="form-control wizard-required"   formControlName="phoneNo" placeholder="Mobile Number" maxlength="10" >
                          <div style="color: #d70b0b;" *ngIf="walkinAddForm.controls.phoneNo.touched && walkinAddForm.controls.phoneNo.invalid">

                            <div  style="color: #d70b0b; margin-top:15px ;" *ngIf="walkinAddForm.controls.phoneNo.errors.pattern">Please, Enter 10 digit Mobile Number.</div>
                        </div>
                                <div  class="wizard-form-error">Mobile Number is Required</div>
                      </div>


                      <div class="col-md-3 form-group">
                        <label>Email <span class="text-danger">*</span></label>
                         <input type="email" class="form-control wizard-required"  formControlName="email" placeholder="Email" >
                          <div style="color: #d70b0b;" *ngIf="!walkinAddForm.controls['email'].valid&&walkinAddForm.controls['email'].dirty">

                              <span *ngIf="walkinAddForm.controls.email.errors.email">Invalid Email.</span>
                          </div>
                            <div style="margin-top:15px ;"  class="wizard-form-error"> Email is Required</div>
                      </div>
                  </div>
                </div>










                  <div class="row">

                    <div class="col-md-6 form-group">

                        <label class="form_row_heading" style="margin-top: 12px;">Vehicle Details</label>
                    </div>


                </div>

                <div class=" row" style="margin-top: -15px">
                  <div class="col-md-3 form-group">
                    <label>Year <span class="text-danger">*</span></label>
                    <select  class="form-control wizard-required" id="txtYear"  formControlName="vechileManufacturingYear" name="txtYear">
                      <option value="" dir="">Year</option>
                      <option value="2021">2021</option>
                        <option value="2020">2020</option>
                        <option value="2019">2019</option>
                        <option value="2018">2018</option>
                        <option value="2017">2017</option>
                        <option value="2016">2016</option>
                        <option value="2015">2015</option>
                        <option value="2014">2014</option>
                        <option value="2013">2013</option>
                        <option value="2012">2012</option>
                        <option value="2011">2011</option>
                        <option value="2010">2010</option>
                        <option value="2009">2009</option>
                        <option value="2008">2008</option>
                        <option value="2007">2007</option>
                        <option value="2006">2006</option>
                        <option value="2005">2005</option>
                        <option value="2004">2004</option>
                        <option value="2003">2003</option>
                        <option value="2002">2002</option>
                        <option value="2001">2001</option>
                        <option value="2000">2000</option>

                    </select>
                    <div class="wizard-form-error">Year is Required</div>
<!--
                    <div *ngIf="walkinAddForm.controls.vechileManufacturingYear.touched && walkinAddForm.controls.vechileManufacturingYear.invalid" class="alert alert-danger">
                      <div *ngIf="walkinAddForm.controls.vechileManufacturingYear.errors.required">Year is required.</div>
                  </div> -->

                    <!-- <span style="color: #d70b0b;" *ngIf="walkinAddForm.controls.vechileManufacturingYear.touched && walkinAddForm.controls.vechileManufacturingYear.invalid">
                        <span *ngIf="walkinAddForm.controls.vechileManufacturingYear.errors.required">year is Required.</span>

                      </span> -->
                </div>
                    <div class="col-md-3 form-group">
                      <label>Make <span class="text-danger">*</span></label>

                        <select class="form-control wizard-required" id="ddlMake" name="ddlMake"  formControlName="vechileBrand" (change)="getModels($event.target.value)">

                            <option value="" dir="">Make</option>
                             <option style="color: black;"  value="{{appointment.vechileBrand}}"> {{appointment.vechileBrand}} </option>
                            <option *ngFor= "let makes of makesdata" [value]="makes.makeId">{{makes.makeName}}</option>
                        </select>
                        <!-- <span style="color: #d70b0b;" *ngIf="walkinAddForm.controls.vechileBrand.touched && walkinAddForm.controls.vechileBrand.invalid">
                          <span *ngIf="walkinAddForm.controls.vechileBrand.errors.required">Make is Required.</span>

                        </span> -->
                        <div class="wizard-form-error">Make is Required</div>
                    </div>

                    <div id="ddlModel" class="form-group col-md-3">
                      <label>Model <span class="text-danger">*</span></label>
                        <select class="form-control wizard-required"  formControlName="vechileModel">
                          <option value="" dir="">Model</option>
                            <option style="color: black;" value="{{appointment.vechileModel}}"> {{appointment.vechileModel}} </option>
                            <option *ngFor= "let models of modelsdata" [value]="models.modelName">{{models.modelName}}</option>
                        </select>
<!--
                        <span style="color: #d70b0b;" *ngIf="walkinAddForm.controls.vechileModel.touched && walkinAddForm.controls.vechileModel.invalid">
                          <span *ngIf="walkinAddForm.controls.vechileModel.errors.required">Make is Required.</span>

                        </span> -->
                        <div class="wizard-form-error">Model is Required</div>
                    </div>
                    <div class="col-md-3 form-group">
                      <label>Mileage</label>
                      <input type="text" class="form-control " id="mileage"  formControlName="mileage" placeholder="Mileage" name="mileage">
                      <!-- <div style="color: #d70b0b;" *ngIf="walkinAddForm.controls.mileage.touched && walkinAddForm.controls.mileage.invalid">

                        <div  style="color: #d70b0b; margin-top:15px ;" *ngIf="walkinAddForm.controls.mileage.errors.pattern"> Enter  Number only.</div>
                    </div>
                      <div class="wizard-form-error">Mileage is Required</div> -->
                    </div>
                </div>


                              <div class="row">

                                        <div class="col-md-6 form-group">
                                          <label style="margin-top: 15px;">VIN  Number</label>
                                          <input type="text" class="form-control " id="mileage"  formControlName="VehicleIdentificationNumber" placeholder="Example:SHUDGUD5457" name="VehicleIdentificationNumber" maxlength="17">
                                          <!-- <div style="color: #d70b0b; margin-top:15px ;"  *ngIf="!walkinAddForm.controls['VehicleIdentificationNumber'].valid&&walkinAddForm.controls['VehicleIdentificationNumber'].dirty">

                                            <span *ngIf="walkinAddForm.controls.VehicleIdentificationNumber.errors.minlength">Incorrect VIN number</span>

                                          </div>
                                                <div  class="wizard-form-error" style="margin-top: 15px;">VIN is Required</div> -->
                                        </div>
                                      </div>
                                      <div class=" row">
                                          <div class="col-md-8 form-group">
                                              <label class="form_row_heading" style="margin-top: 15px;">Preferred Contact Method:<span class="text-danger">*</span></label>
                                              <label class="radio-inline"  style="margin-left: 4.5rem !important;">
                                                  <input class="mr-1"  ng-init="Any" formControlName="preferredContact" type="radio" value="Any" name="preferredContact" id="any" ng-checked="true"><b>Any</b>
                                              </label>
                                              <label class="radio-inline" style="margin-left: 4.5rem !important;">
                                                  <input class="mr-1"  formControlName="preferredContact" type="radio" value="Mobile" name="preferredContact" id="mobile"><b>Mobile</b>
                                              </label>
                                              <label class="radio-inline" style="margin-left: 4.5rem !important;">
                                                  <input class="mr-1"  formControlName="preferredContact" type="radio" value="Email" name="preferredContact" id="email"><b>Email</b>
                                              </label>
                                              <!-- <span style="font-weight: bold;color: red;" *ngIf="!walkinAddForm.controls['preferredContact'].valid&&walkinAddForm.controls['preferredContact'].dirty">
                                                  <span *ngIf="walkinAddForm.controls.preferredContact.errors.required">Email is Required.</span>

                                                </span> -->

                                          </div>


                                      </div>
                    <div class="form-group clearfix">
                      <a href="javascript:;" class="form-wizard-next-btn float-right">Next</a>
                    </div>
                  </fieldset>

                  <fieldset class="wizard-fieldset">
                    <div class=" row">

                      <div class="col-md-12 form-group">
                        <label class="form_row_heading">Select Required Services :<span class="text-danger">*</span></label>
                        <div style="height: 80px;
                        overflow-y: scroll;">

                        <div style="column-count: 3;
                        line-height: 25px;">

                          <div id="ddlMake" *ngFor= "let services of Servicesdata" >
                           <input type="checkbox" name="services"  formControlName="services"  checked="{{services.isCheched}}"
                            [value]="services.value" (change)="getserviceId($event,services.serviceId,services.serviceName)">
                            {{services.serviceName}}
                            <!-- <span style="color: #d70b0b;" *ngIf="walkinAddForm.controls.services.touched && walkinAddForm.controls.services.invalid && walkinAddForm.controls.services.untouched">
                              <span *ngIf="walkinAddForm.controls.services.errors.required">Check Services.</span>
                            </span> -->

                          </div>
                          <div class="wizard-form-error"> Required</div>
                       </div>
                      </div>
                      <br>
                        <div class="row">
                          <div class="col-md-6 form-group">
                            <label class="form_row_heading">Additional services/Comments </label>
                            <textarea type="text" class="form-control" id="comments" style="height: 100px;" formControlName="comments" placeholder="Enter your comments" name="comments"></textarea>

                            </div>

                          <div class="col-md-3 form-group">
                            <label class="form_row_heading">Service Date <span class="text-danger">*</span></label>
                             <input type="text" placeholder="MM/DD/YYYY"
                             onfocus="(this.type='date' )" class="wizard-required" id="date" bsDatepicker minDate="today" class="form-control wizard-required" formControlName="dropOffDate" id="dropDate" name="dropDate">
                             <!-- <span style="color: #d70b0b;" *ngIf="walkinAddForm.controls.dropOffDate.touched && walkinAddForm.controls.dropOffDate.invalid">
                              <span *ngIf="walkinAddForm.controls.dropOffDate.errors.required">Date is Required.</span>

                            </span> -->
                             <div class="wizard-form-error">Date is Required</div>
                            </div>

                          <div class="col-md-3 form-group">

                            <label class="form_row_heading">Appointment Time <span class="text-danger">*</span></label>
                            <input type="time"  min="07:30" max="18:00" class="form-control wizard-required" id="dropTime"   formControlName="dropOffTime" name="dropTime">
                              <!-- <select class="form-control wizard-required" id="dropTime"   formControlName="dropOffTime" name="dropTime">
                                  <option value="" dir="">Time</option>
                                  <option value="7.30 AM">7.30 AM</option>
                                  <option value="8.00 AM">8.00 AM</option>
                                  <option value="8.30 AM">8.30 AM</option>
                                  <option value="9.00 AM">9.00 AM</option>
                                  <option value="9.30 AM">9.30 AM</option>
                                  <option value="10.00 AM">10.00 AM</option>
                                  <option value="10.30 AM">10.30 AM</option>
                                  <option value="11.00 AM">11.00 AM</option>
                                  <option value="11.30 AM">11.30 AM</option>
                                  <option value="12.00 PM">12.00 PM</option>
                                  <option value="12.30 PM">12.30 PM</option>
                                  <option value="1.00 PM">1.00 PM</option>
                                  <option value="1.30 PM">1.30 PM</option>
                                  <option value="2.00 PM">2.00 PM</option>
                                  <option value="2.30 PM">2.30 PM</option>
                                  <option value="3.00 PM">3.00 PM</option>
                                  <option value="3.30 PM">3.30 PM</option>
                                  <option value="4.00 PM">4.00 PM</option>
                                  <option value="4.30 PM">4.30 PM</option>
                                  <option value="5.00 PM">5.00 PM</option>
                                  <option value="5.30 PM">5.30 PM</option>
                                  <option value="6.00 PM">6.00 PM</option>
                              </select> -->
                              <!-- <span style="color: #d70b0b;" *ngIf="walkinAddForm.controls.dropOffTime.touched && walkinAddForm.controls.dropOffTime.invalid">
                                <span *ngIf="walkinAddForm.controls.dropOffTime.errors.required">Time is Required.</span>

                              </span> -->

                                 <div class="wizard-form-error">Time is Required</div>
                          </div>

                      </div>

                      </div>

                  </div>

                    <div class="form-group clearfix">
                      <a href="javascript:;" class="form-wizard-previous-btn float-left">Previous</a>
                      <a href="javascript:;" class="form-wizard-next-btn float-right">Next</a>
                    </div>
                  </fieldset>
                  <fieldset class="wizard-fieldset">
                    <div class="row">
                      <div class="col-md-5">
                        <!-- <label class="form_row_heading">Technicians<span class="text-danger">*</span></label> -->
                      <div class="row">
                      <div class="col-md-4">
                        <label class="form_row_heading">Technicians<span class="text-danger">*</span></label>
                      </div>
                      <div class="col-md-2">

                      </div>
                      <div class="col-md-2">
                        <label class="form_row_heading">Id's</label>
                      </div>
                      </div>
                      </div>
                      <div class="col-md-6">
                        <div class="row">

                          <div class="col-md-7">
                            <label class="form_row_heading ">Select Services <span class="text-danger">*</span></label>
                          </div>

                          <div class="col-md-5">
                            <label class="form_row_heading ">Select Technicians<span class="text-danger">*</span></label>
                          </div>
                        </div>
                      </div>
                      </div>
                    <div class="row">
                      <div class="col-md-5"  style="    background-color: #f1f1f1;
                      padding: 15px;
                      height: 250px;
                      overflow-y: scroll;">

                        <div class="techs-div" *ngFor= "let data of techsdata">
                          <div class="row">
                              <div class="col-md-4">
                                {{data.userName}}
                              </div>
                              <div class="col-md-2">
:
                              </div>
                              <div class="col-md-3">
                                <b>{{data.userId}}</b>
                              </div>
                          </div>
                      </div>


                          </div>

                          <div class="col-md-7">
                          <div  style="    background-color: #f1f1f1;
                          padding: 15px;
                          height: 250px;
                          overflow-y: scroll;">

                            <div *ngFor= "let data of selectedItemsforTech" class="row" >
                                <div class="col-md-6">{{data.ServiceName}} :
                                  </div>

                                  <div class="col-md-5">
                                    <select     (change)="getTechId($event.target.value,data.ServiceId,data.ServiceName)" class="form-control">
                                      <option style="color: black;"  value="{{data.TechnicianName}}"> {{data.TechnicianName}} </option>

                                    <option *ngFor= "let data1 of techsdata" value="{{data1.userId}}">{{data1.userName}}</option>

                                </select>
                                  </div>
                            </div>
                          </div>
                        </div>
                  </div>

                    <div class="form-group clearfix">
                      <a href="javascript:;" class="form-wizard-previous-btn float-left">Previous</a>
                      <button type="submit"  [disabled]="!walkinAddForm.valid" style="float: right;" class="btn  btn-danger text-white text-center" >Submit</button>

                      <!-- <a href="javascript:;" class="form-wizard-submit float-right">Submit</a> -->
                    </div>
                  </fieldset>
                </form>
              </div>
            </div>
          </div>
        </section>
      </div>
  </div>
  </div>
</div>

<!-- existbutton walkin form model end -->


  <div class="modal fade bd-example-modal-lg1" id="demo_update" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
    <div class="modal-dialog modal-xl">
      <div class="modal-content" style="padding-left:15px;padding-right:15px;">
       <button type="button" class="btn btn-danger" data-dismiss="modal" (click)="closepopup()" style="position: absolute;
        right: 5px;border-radius: 50%;top: 5px;width: 35px;height: 35px;">
        <i class="fa fa-times" style="color:#fff;font-size: 20px;
        margin-left: -3px;" aria-hidden="true"></i></button>

        <h4 class="modal-title col-md-9 text-danger mb-3" id="exampleModalLabel" style="margin-top: 15px;">Update Appointment</h4>
        <div class="modal-body">
          <section class="wizard-section">
            <div class="row no-gutters">
              <div class="col-lg-12 col-md-12">
                <div class="form-wizard">
                  <form class="contactform" [formGroup]="walkinUpdateForm" (ngSubmit)="WalkinUpdate()">
                    <div class="form-wizard-header">
                      <ul class="list-unstyled form-wizard-steps clearfix">
                        <li class="active">
                          <span>1</span><br>
                          <p>Service Information</p>
                        </li>
                        <li><span>2</span><br>
                          <p>Customer Information</p>
                        </li>
                        <li><span>3</span><br>
                          <p>Technician  Information</p>
                        </li>
                      </ul>
                    </div>
                    <fieldset class="wizard-fieldset show">

                      <div class=" row">
                        <div class="col-md-8 form-group">
                          <label class="form_row_heading">Selected Services:<span class="text-danger">*</span></label>
                          <div style="height: 150px;
                          overflow-y: scroll;">
                          <div style="column-count: 3;
                          line-height: 25px;">
                            <div id="ddlMake" *ngFor= "let services of Servicesdata">
                             <input type="checkbox" name="services" formControlName="services"  checked="{{services.isCheched}}"
                              [value]="services.serviceId"  (change)="getserviceId($event,services.serviceId,services.serviceName)">
                              {{services.serviceName}}
                            </div>
                         </div>
                        </div>


                        </div>

                        <div class="col-md-3 form-group">
                          <label class="form_row_heading">Status <span class="text-danger">*</span></label>
                            <select class="form-control" id="status"  formControlName ="status" name="status"  (change)="AppointmentStatus($event.target.value)">

                                <option value="1">Confirmed</option>
                                <option value="2">Technician Assigned</option>
                                <option value="3">In-Progress</option>
                                <option value="4">Work Done-Technician</option>
                                <option value="5">Confirmed by Supervisor</option>
                                <option value="6">Pickup Scheduled</option>
                                <option value="7">Cancelled</option>
                                <option value="8">Re-schedule</option>
                                <option value="9">Accepted</option>
                            </select>
                        </div>
                      </div>

                      <div class=" row">
                        <div class="col-md-10 form-group">
                          <div class="row">
                            <div class="col-md-6 form-group">
                              <label class="form_row_heading">Additional services/Comments</label>
                              <textarea type="text" class="form-control" id="comments" style="height: 100px;" formControlName="comments" placeholder="Enter your comments" name="comments"></textarea>
                            </div>
                            <div class="col-md-3 form-group">
                              <label class="form_row_heading">Service Date <span class="text-danger">*</span></label>
                               <input type="text" placeholder="MM/DD/YYYY"
                               onfocus="(this.type='date' )" id="date" bsDatepicker minDate="today" class="form-control wizard-required" formControlName="dropOffDate" id="dropDate" name="dropDate">
                               <div class="wizard-form-error">Select Service date</div>
                              </div>

                            <div class="col-md-3 form-group">


                              <label class="form_row_heading">Appointment Time <span class="text-danger">*</span></label>
                              <input type="time" min="07:30" max="18:00" class="form-control wizard-required" id="dropTime"   formControlName="dropOffTime" name="dropTime">

                                  <div class="wizard-form-error">Time Is Required</div>
                            </div>


                        </div>


                        </div>

                    </div>

                      <div class="form-group clearfix">
                        <a href="javascript:;" class="form-wizard-next-btn float-right">Next</a>
                      </div>
                    </fieldset>
                    <fieldset class="wizard-fieldset">

                    <div class="row">

                      <div class="col-md-6 form-group">

                          <label class="form_row_heading">Vehicle Details</label>
                      </div>


                  </div>

                  <div class=" row" style="margin-top: -15px">
                    <div class="col-md-3 form-group">
                      <label class="font-weight-bold">Year <span class="text-danger">*</span></label>
                      <select aria-readonly="true" class="form-control wizard-required" id="txtYear1"  formControlName="vechileManufacturingYear" name="txtYear1">
                          <option style="color: black;" value="{{appointment.vechileManufacturingYear}}"> {{appointment.vechileManufacturingYear}} </option>
                          <option value="2021">2021</option>
                          <option value="2020">2020</option>
                          <option value="2019">2019</option>
                          <option value="2018">2018</option>
                          <option value="2017">2017</option>
                          <option value="2016">2016</option>
                          <option value="2015">2015</option>
                          <option value="2014">2014</option>
                          <option value="2013">2013</option>
                          <option value="2012">2012</option>
                          <option value="2011">2011</option>
                          <option value="2010">2010</option>
                          <option value="2009">2009</option>
                          <option value="2008">2008</option>
                          <option value="2007">2007</option>
                          <option value="2006">2006</option>
                          <option value="2005">2005</option>
                          <option value="2004">2004</option>
                          <option value="2003">2003</option>
                          <option value="2002">2002</option>
                          <option value="2001">2001</option>
                          <option value="2000">2000</option>

                      </select>
                      <div class="wizard-form-error">year Is Required</div>
                      <!-- <span style="font-weight: bold;color: red;" *ngIf="!walkinUpdateForm.controls['vechileManufacturingYear'].valid&&walkinUpdateForm.controls['vechileManufacturingYear'].dirty">
                        <span *ngIf="walkinUpdateForm.controls.vechileManufacturingYear.errors.required">year is Required.</span>
                      </span> -->
                  </div>
                      <div class="col-md-3 form-group">

                          <!--  <input type="text" class="form-control" id="txtMake" placeholder=" Make(Brand) ">-->
                          <label class="font-weight-bold">Make<span class="text-danger">*</span></label>
                          <select   class="form-control" id="ddlMake1" name="ddlMake1"  formControlName="vechileBrand" (change)="getModels($event.target.value)">
                              <option style="color: black;"  value="{{appointment.vechileBrand}}"> {{appointment.vechileBrand}} </option>
                              <option *ngFor= "let makes of makesdata" [value]="makes.makeId">{{makes.makeName}}</option>
                          </select>
                          <span style="font-weight: bold;color: red;" *ngIf="!walkinUpdateForm.controls['vechileBrand'].valid&&walkinUpdateForm.controls['vechileBrand'].dirty">
                            <span *ngIf="walkinUpdateForm.controls.vechileBrand.errors.required">year is Required.</span>
                          </span>
                      </div>

                      <div id="ddlModel" class="form-group col-md-3">
                        <label class="font-weight-bold">Model <span class="text-danger">*</span></label>
                          <select  class="form-control"  formControlName="vechileModel" name="vechileModel1">
                              <option style="color: black;" value="{{appointment.vechileModel}}"> {{appointment.vechileModel}} </option>
                              <option *ngFor= "let models of modelsdata" [value]="models.modelName">{{models.modelName}}</option>
                          </select>

                      </div>

                  </div>

                  <div class="row">

                    <div class="col-md-3 form-group">
                      <label class="font-weight-bold">Mileage</label>
                        <input type="text"   class="form-control " id="mileage"  formControlName="mileage" placeholder="Car Mileage" name="mileage">
                        <!-- <div style="color: #d70b0b;" *ngIf="walkinUpdateForm.controls.mileage.touched && walkinUpdateForm.controls.mileage.invalid">

                          <div  style="color: #d70b0b; margin-top:15px ;" *ngIf="walkinUpdateForm.controls.mileage.errors.pattern"> Enter  Number only.</div>
                      </div> -->
                        <!-- <div class="wizard-form-error">Mileage is Required</div> -->
                      </div>
                    <div class="col-md-3 form-group">
                      <label>VIN Number</label>
                      <input type="text" class="form-control " id="mileage"  formControlName="VehicleIdentificationNumber" placeholder="Example:SHUDGUD5457" name="VehicleIdentificationNumber" maxlength="17">
                      <!-- <div style="color: #d70b0b; margin-top:15px ;"  *ngIf="!walkinUpdateForm.controls['VehicleIdentificationNumber'].valid&&walkinUpdateForm.controls['VehicleIdentificationNumber'].dirty">

                        <span *ngIf="walkinUpdateForm.controls.VehicleIdentificationNumber.errors.minlength">Incorrect VIN number</span>

                      </div>
                      <div class="wizard-form-error">VIN is Required</div> -->
                    </div>
                    <div class="col-md-6 form-group">
                      <label class="font-weight-bold" style="margin-top: 36px;">Preferred Contact Method:<span class="text-danger">*</span></label>
                      <label class="radio-inline ml-4">
                          <input readonly class="mr-1 "  formControlName="preferredContact" type="radio"  name="preferredContact" id="any" value="Any" checked><b>Any</b>
                      </label>
                      <label  class="radio-inline ml-4">
                          <input readonly class="mr-1 "  formControlName="preferredContact" type="radio"  name="preferredContact" id="mobile" value="Mobile"><b>Mobile</b>
                      </label>
                      <label class="radio-inline ml-4">
                          <input readonly class="mr-1 "  formControlName="preferredContact" type="radio"  name="preferredContact" id="email" value="Email"><b>Email</b>
                      </label>


                  </div>
                  </div>

                  <div class="row">

                    <div class="col-md-6 form-group">

                        <label class="form_row_heading">Customer Details</label>
                    </div>


                </div>
                  <div class="row" style="margin-top: -15px">
                    <div class="col-md-3 form-group">
                      <label>First Name <span class="text-danger">*</span></label>
                        <input type="text" class="form-control wizard-required" id="txtfName"  formControlName="firstName" placeholder="First Name" name="txtfName" aria-controls="example" required>
                          <div class="wizard-form-error">First Name is Required</div>
                    </div>

                    <div class="col-md-3 form-group">
                      <label>Last Name </label>
                        <input type="text" class="form-control " id="txtlName"  formControlName="lastName" placeholder="Last Name" name="txtlName">
                        <!-- <div class="wizard-form-error">Last Name is Required</div> -->
                    </div>


                    <div class="col-md-3 form-group">

                      <label>Mobile Number <span class="text-danger">*</span></label>
                        <input type="text" class="form-control wizard-required"   formControlName="phoneNo" placeholder="Mobile Number" maxlength="10" >
                        <div style="color: #d70b0b;" *ngIf="walkinUpdateForm.controls.phoneNo.touched && walkinUpdateForm.controls.phoneNo.invalid">

                          <div  style="color: #d70b0b; margin-top:15px ;" *ngIf="walkinUpdateForm.controls.phoneNo.errors.pattern">Please, Enter 10 digit Mobile Number.</div>
                      </div>
                              <div  class="wizard-form-error">Mobile Number is Required</div>
                    </div>

                    <div class="col-md-3 form-group">
                      <label>Email <span class="text-danger">*</span></label>
                       <input type="email" class="form-control wizard-required"  formControlName="email" placeholder="Email" >
                        <div style="color: #d70b0b;" *ngIf="!walkinUpdateForm.controls['email'].valid&&walkinUpdateForm.controls['email'].dirty">

                            <span *ngIf="walkinUpdateForm.controls.email.errors.email">Invalid Email</span>
                        </div>
                          <div style="margin-top:15px ;"  class="wizard-form-error"> Email is Required</div>
                    </div>



                </div>

                      <div class="form-group clearfix">
                        <a href="javascript:;" class="form-wizard-previous-btn float-left">Previous</a>
                        <a href="javascript:;" class="form-wizard-next-btn float-right">Next</a>
                      </div>
                    </fieldset>

                    <fieldset class="wizard-fieldset">
                      <div class="row">
                        <div class="col-md-5">
                          <!-- <label class="form_row_heading">Technicians<span class="text-danger">*</span></label> -->
                        <div class="row">
                        <div class="col-md-4">
                          <label class="form_row_heading">Technicians<span class="text-danger">*</span></label>
                        </div>
                        <div class="col-md-2">

                        </div>
                        <div class="col-md-2">
                          <label class="form_row_heading">Id's</label>
                        </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">

                            <div class="col-md-7">
                              <label class="form_row_heading ">Selected Services <span class="text-danger">*</span></label>
                            </div>

                            <div class="col-md-5">
                              <label class="form_row_heading ">Technician Assigned <span class="text-danger">*</span></label>
                            </div>
                          </div>
                        </div>
                        </div>

                      <div class="row">
                        <div class="col-md-5"  style="    background-color: #f1f1f1;
                        padding: 15px;
                        height: 250px;
                        overflow-y: scroll;">


                          <div class="techs-div" *ngFor= "let data of techsdata">


                            <div class="row">
                                <div class="col-md-5">
                                  {{data.userName}}
                                </div>
                                <div class="col-md-1">
  :
                                </div>
                                <div class="col-md-3">
                                  <b>{{data.userId}}</b>
                                </div>
                            </div>
                        </div>


                            </div>

                            <div class="col-md-7">
                            <div  style="    background-color: #f1f1f1;
                            padding: 15px;
                            height: 250px;
                            overflow-y: scroll;">

                              <div *ngFor= "let data of bindSelected" class="row" >
                                  <div class="col-md-6">{{data.ServiceName}} :
                                    </div>

                                    <div class="col-md-5">
                                      <select     (change)="getTechId2($event.target.value,data.ServiceId,data.ServiceName,data.TechnicianName)" class="form-control">
                                        <option style="color: black;"  value="{{data.TechnicianName}}"> {{data.TechnicianName}} </option>
                                      <option *ngFor= "let data1 of techsdata" value="{{data1.userId}}">{{data1.userName}}</option>

                                  </select>
                                    </div>
                              </div>
                            </div>
                          </div>
                    </div>


                      <div class="form-group clearfix">
                        <a href="javascript:;" class="form-wizard-previous-btn float-left">Previous</a>
                        <button type="submit"  style="float: right;" class="btn  btn-danger text-white text-center" >Submit</button>

                        <!-- <a href="javascript:;" class="form-wizard-submit float-right">Submit</a> -->
                      </div>

            <input type="hidden"  class="form-control" formControlName="appointmentId"     name="appointmentId">
            <input type="hidden"   class="form-control" formControlName="customerId"    name="customerId">
            <input type="hidden"  class="form-control"   formControlName="clientId"    name="clientId">
            <input type="hidden"  class="form-control"  formControlName="reviewRemindCount"    name="reviewRemindCount">
            <!-- <input type="text"   class="form-control"  formControlName="serviceRemindCount"    name="serviceRemindCount"> -->
            <input type="hidden"  class="form-control"  formControlName="reviewStatus"     name="reviewStatus">
            <input type="hidden"   class="form-control"  formControlName="createdOn"     name="createdOn">
            <input type="hidden"   class="form-control"  formControlName="appointmentFrom"     name="appointmentFrom">
            <input type="hidden"   class="form-control"  formControlName="comments"     name="comments">
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>


    </div>
    </div>
  </div>


  <!-- end row -->
  <!-- view customer model popup -->
  <div class="modal fade bd-example-modal-lg5" id="demo-customers" tabindex="-1" style="overflow:scroll;">
    <div class="modal-dialog modal-xl appointment_popup_main">
      <div class="modal-content" style="padding-left:15px;padding-right:15px;">
        <h4 class="modal-title col-md-9 text-danger" id="exampleModalLabel" style="margin-bottom:0px;
        margin-top:15px;
        margin-left: 5px;">Appointment Details</h4>
        <button type="button" class="btn btn-danger" data-dismiss="modal"  style="position: absolute;
        right: 4px;border-radius: 50%;
        top: 2px;">&times;</button>
        <button type="button" class="close" data-dismiss="modal"><i class="icon-xs-o-md"></i></button>
        <div class="modal-body" style="font-size: 13px;">


          <div class="row" style="margin-top: 10px;margin-bottom: 10px; margin-left: 240px; font-size: 14px;">
            <div class="col-md-3">
              <label class="font-weight-bold">Customer ID</label>
              <label> : {{appointment.customerId}}</label>

            </div>

            <div class="col-md-3">
              <label class="font-weight-bold">Appointment ID</label>
              <label> : {{appointment.appointmentId}}</label>

            </div>
            <div  class="col-md-4">
              <label class="font-weight-bold">Appointment Type</label>

              <label> : {{appointment.appointmentFrom}}</label>
            </div>
          </div>
          <div class="row">

            <div class="col-md-6 form-group">

                <ul class="nav nav-tabs" style="height: 38px;margin-bottom: 15px;">
                  <li class="nav-item">
                    <a class="nav-link active" style="    background-color: #211e50;
                    color: white;border-top-right-radius: 25px ;    height: 38px; font-weight: 700; padding-bottom: 27px;">Customer Details</a>
                  </li>


                </ul>
            </div>


        </div>
            <div class="row" style="margin-top: -15px">
              <div class="col-md-4 form-group">
                <label class="font-weight-bold"> Name </label>
                <label> : {{appointment.firstName}}  {{appointment.lastName}}</label>

                    </div>

                    <div class="col-md-4 form-group">
                      <label class="font-weight-bold">Mobile Number </label>
                      <label> : {{appointment.phoneNo}}</label>
                         </div>
                    <div class="col-md-4">
                      <label class="font-weight-bold">Email </label>
                      <label> : {{appointment.email}}</label>
                        </div>




          </div>



          <div class="row">

              <div class="col-md-6 form-group">


                  <ul class="nav nav-tabs" style="height: 38px; margin-bottom: 15px;">
                    <li class="nav-item">
                      <a class="nav-link active" style="    background-color: #211e50;
                      color: white;border-top-right-radius: 25px ;    height: 38px;font-weight: 700;padding-bottom: 27px;padding-right: 30PX;">Vehicle Details</a>
                    </li>

                  </ul>
              </div>


          </div>


          <div class=" row" style="margin-top: -15px">
            <div class="col-md-4 form-group">
              <label class="font-weight-bold">Year </label>
              <label> : {{appointment.vechileManufacturingYear}}</label><br>
          </div>
              <div class="col-md-4 form-group">

                  <!--  <input type="text" class="form-control" id="txtMake" placeholder=" Make(Brand) ">-->
                  <label class="font-weight-bold">Make</label>
                  <label>: {{appointment.vechileBrand}}</label><br>
              </div>
              <div id="ddlModel" class="form-group col-md-4">
                <label class="font-weight-bold">Model </label>
                <label> : {{appointment.vechileModel}}</label><br>
              </div>

          </div>


          <div class="row" >

            <div class="col-md-4">
              <label class="font-weight-bold">Mileage </label>
              <label> : {{appointment.mileage}} miles</label>
                </div>
                <div class="col-md-4">
                  <label class="font-weight-bold">VIN </label>
                  <label>: {{appointment.vehicleIdentificationNumber}}</label>
                    </div>
          </div>

          <div class="row">

            <div class="col-md-6 form-group">



                <ul class="nav nav-tabs" style="height: 38px; margin-top: 15px; ">
                  <li class="nav-item">
                    <a class="nav-link active" style="    background-color: #211e50;
                    color: white;border-top-right-radius: 25px ;    height: 38px;font-weight: 700; padding-bottom: 27px;padding-right: 30PX;">Service Details</a>
                  </li>

                </ul>
            </div>
          </div>
          <div class="row" style="margin-top: -10px;">
            <div  class="col-md-4">
              <label class="font-weight-bold ">Services Requested</label>

              <label class="mt-3 mb-3">: {{appointment.serviceRequested}}</label>
            </div>
            <div class="col-md-4">
              <label class="font-weight-bold">Additional Services</label>

              <label  class="mt-3 mb-3">: {{appointment.additionalServices}}</label>
                </div>
                <div class="col-md-4">
                  <label class="font-weight-bold">Removed Services</label>

                  <label  class="mt-3 mb-3">: {{appointment.removedServices}}</label>
                    </div>
          </div>

<div class="row" style="margin-top: 10px;">

  <div  class="col-md-4">
    <label class="font-weight-bold">Drop Off Date</label>

    <label> : {{appointment.dropOffDate |  date:'MM-dd-yyyy'}}</label>
  </div>
  <div  class="col-md-4">
    <label class="font-weight-bold">Drop Off Time</label>

    <label> : {{appointment.dropOffTime}}</label>
  </div>

  <div class="col-md-4">
    <label class="font-weight-bold">Comments:</label>

    <label> {{appointment.comments}}</label>
  </div>
</div>

<div style="margin-top: 15px;">
  <div class="row">
    <div  class="col-md-6">
      <label class="font-weight-bold">Appointment status</label>

      <label> :  {{VechileStatus(appointment.status)}}</label>
    </div>
  </div>
</div>



        </div>
    </div>
    </div>
  </div>






 <!-- [ Modal #2 ] -->
    <div class="modal fade" id="demo-2" tabindex="-1">
      <div class="modal-dialog modal-lg" style="margin-left: 250px;">
       <div class="modal-content" style="background-image: url(../../../assets/images/customer_pop2.png);min-height: 250px;">
         <img src="../../../assets/images/customer_pop1.png" alt="pop_animation" class="pop_animation_customer">
         <div class="row">
          <div class="col-md-12" style="padding-top: 15px;
          padding-bottom: 15px;
          padding-left: 32px;">
            <h4 style="text-align: center;font-family: 'Calistoga', cursive;">Customer Information<br>
             <img src="../../../assets/images/customer_underline.png" alt="customer_heading" class="customer_image_underline">
            </h4>

            <button type="button" class="btn btn-danger" data-dismiss="modal"  style="position: absolute;
            right: 24px;border-radius: 50%;
            top: 5px;">&times;</button>
            <button type="button" class="close" data-dismiss="modal"><i class="icon-xs-o-md"></i></button>
          </div>
         </div>
            <div>
              <div class="container">
                <table cellpadding="3" cellspacing="0" border="0" style="width: 100%; margin: 0 auto 2em auto;">
                  <tbody class="row">
                      <tr id="filter_col0" data-column="0" class="col-md-4">
                        <label>First Name </label>
                        <input type="text" class="column_filter form-control" id="col0_filter">

                      </tr>
                      <tr id="filter_col1" data-column="1" class="col-md-4">
                        <label>Last Name </label>
                        <input type="text" class="column_filter form-control" id="col1_filter">
                            </tr>
                      <tr id="filter_col2" data-column="2" class="col-md-4">
                        <label>Phone Number </label>
                        <input type="text" class="column_filter form-control" id="col2_filter">
                            </tr>
                  </tbody>
              </table>
              <div>


              <div class="table-responsive" id="showDiv" style="width: 100%;height:300px;display:none;margin-bottom:75px;">
                  <table class="table table-striped table-bordered" id="examplepopup" *ngIf="showTable" style="width:100%;">
                      <thead>
                          <tr>
                          <th>First Name</th>
                          <th>Last Name</th>
                          <th>Phone Number</th>
                          <th>Action</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr *ngFor= "let completeds of datacompleted">
                       <!-- <td>{{datas.appointmentId}}</td> -->
                       <td>{{completeds.firstName}}</td>
                       <td>{{completeds.lastName}}</td>
                       <td>{{completeds.phoneNo}}</td>
                       <td>
                        <button type="button" class="btn btn-primary text-white text-center" data-toggle="modal"
                        data-target="#demo-4" data-dismiss="modal" (click)="GetAppointmentByIdExsting(completeds.appointmentId)">Select</button>
                    </td>
                       <!-- <td>{{datas.status}}</td> -->
                      </tr>
                      </tbody>
                  </table>

              </div>
              </div>
            </div>
       </div>
      </div>
    </div>

 </div>


  <!-- [ Modal #6 for nc inspection ] -->
  <div class="modal fade" id="demo-6" tabindex="-1">
    <div class="modal-dialog modal-xl appointment_popup_main" style="margin-left:28%;margin-top: 10%; margin-right: 20%;">
      <div class="modal-content" style="padding-left:15px;padding-right:15px;">
        <h4 class="modal-title col-md-9 text-danger" id="exampleModalLabel" style="margin-bottom:15px;
        margin-top:15px;margin-left: 5px;">NC-Inspection</h4>
        <button type="button"  (click)="closepopup()" class="btn btn-danger" data-dismiss="modal"  style="position: absolute;
        right: 4px;border-radius: 50%;
        top: 4px;">&times;</button>
        <button type="button" class="close" data-dismiss="modal"><i class="icon-xs-o-md"></i></button>
        <button type="button" (click)="getallData()" class="btn btn-danger text-white text-center" data-toggle="modal"
        data-target="#demo-3" data-dismiss="modal" style="width: 170px;
        position: absolute;
        right: 100px;
        top: 15px;">Existing Customer</button>
        <div class="modal-body">
          <section class="wizard-section">
            <div class="row no-gutters">
              <div class="col-lg-12 col-md-12">
                <div class="form-wizard">
                  <form  role="form" [formGroup]="Inspection" (ngSubmit)="WalkinAddForinspection()">
                    <div class="form-wizard-header">
                      <ul class="list-unstyled form-wizard-steps clearfix" style="margin-left: 13%;">
                        <li class="active">
                          <span>1</span><br>
                          <p>Customer Information</p>
                        </li>
                        <!-- <li><span>2</span><br>
                          <p>Service Information</p>
                        </li> -->
                        <li><span>2</span><br>
                          <p>Technician  Information</p>
                        </li>
                      </ul>
                    </div>
                    <fieldset class="wizard-fieldset show">
                      <div>
                        <div class="row">
                          <div class="col-md-6 form-group">
                              <label class="form_row_heading">Customer Details</label>
                          </div>
                      </div>

                      <div class="row" style="margin-top: -15px">
                        <div class="col-md-3 form-group">
                          <label>First Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control wizard-required" id="txtfName"  formControlName="firstName" placeholder="First Name" name="txtfName" aria-controls="example" required>
                            <!-- <span style="font-weight: bold;color: red;font-size: 14PX;"  *ngIf="!walkinAddForm.controls['firstName'].valid&&walkinAddForm.controls['firstName'].dirty">
                                <span *ngIf="walkinAddForm.controls.firstName.errors.required">First Name is Required.</span>
                                <span *ngIf="walkinAddForm.controls.firstName.errors.minlength">User name minimum should be length of 3.</span>
                                <span *ngIf="walkinAddForm.controls.firstName.errors.maxlength">User name maximum should be length of 50.</span>
                              </span> -->
                              <div class="wizard-form-error">First Name is Required</div>
                        </div>

                        <div class="col-md-3 form-group">
                          <label>Last Name <span class="text-danger">*</span></label>
                            <input type="text" class="form-control wizard-required" id="txtlName"  formControlName="lastName" placeholder="Last Name" name="txtlName">
                            <div class="wizard-form-error">Last Name is Required</div>
                        </div>

                        <div class="col-md-3 form-group">

                          <label>Mobile Number <span class="text-danger">*</span></label>
                            <input type="text" class="form-control wizard-required"   formControlName="phoneNo" placeholder="Mobile Number" maxlength="10" >
                            <div style="color: #d70b0b;" *ngIf="Inspection.controls.phoneNo.touched && Inspection.controls.phoneNo.invalid">

                              <div  style="color: #d70b0b; margin-top:15px ;" *ngIf="Inspection.controls.phoneNo.errors.pattern">Please, Enter 10 digit Mobile Number.</div>
                          </div>
                                  <div  class="wizard-form-error">Mobile Number is Required</div>
                        </div>

                        <div class="col-md-3 form-group">
                          <label>Email <span class="text-danger">*</span></label>
                           <input type="email" class="form-control wizard-required"  formControlName="email" placeholder="Email" >
                            <div style="color: #d70b0b;" *ngIf="!Inspection.controls['email'].valid&&Inspection.controls['email'].dirty">

                                <span *ngIf="Inspection.controls.email.errors.email">Invalid Email</span>
                            </div>
                              <div style="margin-top:15px ;"  class="wizard-form-error"> Email is Required</div>
                        </div>
                    </div>
                  </div>
                    <div class="row">

                      <div class="col-md-6 form-group">

                          <label class="form_row_heading" style="margin-top: 20px;">Vehicle Details</label>
                      </div>


                  </div>


                  <div class=" row" style="margin-top: -15px">
                    <div class="col-md-3 form-group">
                      <label>Year <span class="text-danger">*</span></label>
                      <select  class="form-control wizard-required " id="txtYear"  formControlName="vechileManufacturingYear" name="txtYear">
                        <option value="" dir="">Year</option>
                        <option value="2021">2021</option>
                          <option value="2020">2020</option>
                          <option value="2019">2019</option>
                          <option value="2018">2018</option>
                          <option value="2017">2017</option>
                          <option value="2016">2016</option>
                          <option value="2015">2015</option>
                          <option value="2014">2014</option>
                          <option value="2013">2013</option>
                          <option value="2012">2012</option>
                          <option value="2011">2011</option>
                          <option value="2010">2010</option>
                          <option value="2009">2009</option>
                          <option value="2008">2008</option>
                          <option value="2007">2007</option>
                          <option value="2006">2006</option>
                          <option value="2005">2005</option>
                          <option value="2004">2004</option>
                          <option value="2003">2003</option>
                          <option value="2002">2002</option>
                          <option value="2001">2001</option>
                          <option value="2000">2000</option>

                      </select>
                      <div class="wizard-form-error">Year is Required</div>
                      <!-- <span style="font-weight: bold;color: red;" *ngIf="!walkinAddForm.controls['vechileManufacturingYear'].valid&&walkinAddForm.controls['vechileManufacturingYear'].dirty">
                          <span *ngIf="walkinAddForm.controls.vechileManufacturingYear.errors.required">year is Required.</span>

                        </span> -->
                  </div>
                      <div class="col-md-3 form-group">
                        <label>Make <span class="text-danger">*</span></label>
                          <!--  <input type="text" class="form-control" id="txtMake" placeholder=" Make(Brand) ">-->
                          <select class="form-control wizard-required" id="ddlMake" name="ddlMake"  formControlName="vechileBrand" (change)="getModels($event.target.value)">
                            <option value="" dir="">Make</option>
                               <option style="color: black;"  value="{{appointment.vechileBrand}}"> {{appointment.vechileBrand}} </option>
                              <option *ngFor= "let makes of makesdata" [value]="makes.makeId">{{makes.makeName}}</option>
                          </select>
                          <div class="wizard-form-error">Make Required</div>
                      </div>

                      <div id="ddlModel" class="form-group col-md-3">
                        <label>Model <span class="text-danger">*</span></label>
                          <select class="form-control wizard-required"  formControlName="vechileModel">
                            <option value="" dir="">Model</option>
                              <option style="color: black;" value="{{appointment.vechileModel}}"> {{appointment.vechileModel}} </option>
                              <option *ngFor= "let models of modelsdata" [value]="models.modelName">{{models.modelName}}</option>
                          </select>
                          <div class="wizard-form-error">Model Required</div>
                      </div>
                      <div class="col-md-3 form-group">
                        <label>Mileage</label>
                        <input type="text" class="form-control " id="mileage"  formControlName="mileage" placeholder="Mileage" name="mileage">
                        <!-- <div style="color: #d70b0b;" *ngIf="Inspection.controls.mileage.touched && Inspection.controls.mileage.invalid">

                          <div  style="color: #d70b0b; margin-top:15px ;" *ngIf="Inspection.controls.mileage.errors.pattern"> Enter  Number only.</div>
                      </div>
                        <div class="wizard-form-error">Mileage is Required</div> -->
                      </div>
                  </div>


                                <div class="row">

                                          <div class="col-md-6 form-group">
                                            <label style="margin-top: 10PX;">VIN  Number</label>
                                            <input type="text" class="form-control " id="mileage"  formControlName="VehicleIdentificationNumber" placeholder="Example:SHUDGUD5457" name="VehicleIdentificationNumber" maxlength="17">
                                            <!-- <div style="color: #d70b0b; margin-top:15px ;"  *ngIf="!Inspection.controls['VehicleIdentificationNumber'].valid&&Inspection.controls['VehicleIdentificationNumber'].dirty">

                                              <span *ngIf="Inspection.controls.VehicleIdentificationNumber.errors.minlength">Incorrect VIN number </span>

                                            </div>
                                            <div class="wizard-form-error" style="margin-top: 10PX;">VIN is Required</div> -->
                                          </div>
                                        </div>
                                        <div class=" row">
                                            <div class="col-md-8 form-group">
                                                <label class="form_row_heading" style="margin-top: 10PX;">Preferred Contact Method:<span class="text-danger">*</span></label>
                                                <label class="radio-inline" style="margin-left: 4.5rem !important;">
                                                    <input class="mr-1 "  formControlName="preferredContact" type="radio" value="Any" name="preferredContact" id="any" checked><b>Any</b>
                                                </label>
                                                <label class="radio-inline" style="margin-left: 4.5rem !important;">
                                                    <input class="mr-1 "  formControlName="preferredContact" type="radio" value="Mobile" name="preferredContact" id="mobile"><b>Mobile</b>
                                                </label>
                                                <label class="radio-inline" style="margin-left: 4.5rem !important;">
                                                    <input class="mr-1 "  formControlName="preferredContact" type="radio" value="Email" name="preferredContact" id="email"><b>Email</b>
                                                </label>
                                                <!-- <span style="font-weight: bold;color: red;" *ngIf="!walkinAddForm.controls['preferredContact'].valid&&walkinAddForm.controls['preferredContact'].dirty">
                                                    <span *ngIf="walkinAddForm.controls.preferredContact.errors.required">Email is Required.</span>

                                                  </span> -->

                                            </div>

                                        </div>
                      <div class="form-group clearfix">
                        <a href="javascript:;" class="form-wizard-next-btn float-right">Next</a>
                      </div>
                    </fieldset>

                    <!-- <fieldset class="wizard-fieldset">
                      <div class=" row">

                        <div class="col-md-12 form-group">
                          <label class="form_row_heading">By Defualt NC-Incpection  Service is Selected.</label>
                          <div style="height: 80px;
                          overflow-y: scroll;">
                          <div style="column-count: 3;
                          line-height: 25px;">
                            <div id="ddlMake" *ngFor= "let services of Servicesdata">
                              <div *ngIf="services.serviceId===14">
                                <input type="checkbox" name="services" formControlName="services"  checked="{{services.isCheched}}"
                                [value]="services.value" (change)="getserviceId($event,services.serviceId,services.serviceName)">
                                {{services.serviceName}}
                              </div>

                            </div>
                         </div>
                        </div>
                        <br>
                          <div class="row">
                            <div class="col-md-6 form-group">
                              <label class="form_row_heading">Additional services/Comments </label>
                              <textarea type="text" class="form-control" id="comments" style="height: 100px;" formControlName="comments" placeholder="Enter your comments" name="comments"></textarea>

                              </div>



                        </div>

                        </div>

                    </div>

                      <div class="form-group clearfix">
                        <a href="javascript:;" class="form-wizard-previous-btn float-left">Previous</a>
                        <a href="javascript:;" class="form-wizard-next-btn float-right">Next</a>
                      </div>
                    </fieldset>	 -->
                    <fieldset class="wizard-fieldset">

                      <div class="row">

                        <div class="col-md-3 form-group">
                          <label class="form_row_heading">Service Date <span class="text-danger">*</span></label>
                           <input type="text" placeholder="MM/DD/YYYY"
                           onfocus="(this.type='date' )" id="date" bsDatepicker minDate="today" class="form-control wizard-required" formControlName="dropOffDate" id="dropDate" name="dropDate">
                           <div class="wizard-form-error">Date is Required</div>
                          </div>

                        <div class="col-md-3 form-group">

                          <label class="form_row_heading">Appointment Time <span class="text-danger">*</span></label>
                          <input type="time" min="07:30" max="18:00"  class="form-control wizard-required" id="dropTime"   formControlName="dropOffTime" name="dropTime">

                            <!-- <select class="form-control wizard-required" id="dropTime"   formControlName="dropOffTime" name="dropTime">
                              <option value="" dir="">Time</option>
                                <option value="7.30 AM">7.30 AM</option>
                                <option value="8.00 AM">8.00 AM</option>
                                <option value="8.30 AM">8.30 AM</option>
                                <option value="9.00 AM">9.00 AM</option>
                                <option value="9.30 AM">9.30 AM</option>
                                <option value="10.00 AM">10.00 AM</option>
                                <option value="10.30 AM">10.30 AM</option>
                                <option value="11.00 AM">11.00 AM</option>
                                <option value="11.30 AM">11.30 AM</option>
                                <option value="12.00 PM">12.00 PM</option>
                                <option value="12.30 PM">12.30 PM</option>
                                <option value="1.00 PM">1.00 PM</option>
                                <option value="1.30 PM">1.30 PM</option>
                                <option value="2.00 PM">2.00 PM</option>
                                <option value="2.30 PM">2.30 PM</option>
                                <option value="3.00 PM">3.00 PM</option>
                                <option value="3.30 PM">3.30 PM</option>
                                <option value="4.00 PM">4.00 PM</option>
                                <option value="4.30 PM">4.30 PM</option>
                                <option value="5.00 PM">5.00 PM</option>
                                <option value="5.30 PM">5.30 PM</option>
                                <option value="6.00 PM">6.00 PM</option>
                            </select>
                             -->
                            <!-- <span style="font-weight: bold;color: red;" *ngIf="!walkinAddForm.controls['dropOffDate'].valid&&walkinAddForm.controls['dropOffDate'].dirty">
                                <span *ngIf="walkinAddForm.controls.dropOffDate.errors.required">Time is Required.</span>

                              </span> -->
                              <div class="wizard-form-error">Time is Required</div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-md-5">
                          <!-- <label class="form_row_heading">Technicians<span class="text-danger">*</span></label> -->
                        <div class="row">
                        <div class="col-md-4">
                          <label class="form_row_heading">Technicians<span class="text-danger">*</span></label>
                        </div>
                        <div class="col-md-2">

                        </div>
                        <div class="col-md-3">
                          <label class="form_row_heading">Id's</label>
                        </div>
                        </div>
                        </div>
                        <div class="col-md-6">
                          <div class="row">

                            <div class="col-md-6">
                              <label class="form_row_heading ">Selected Services <span class="text-danger">*</span></label>
                            </div>

                            <div class="col-md-5">
                              <label class="form_row_heading ">Select Technicians <span class="text-danger">*</span></label>
                            </div>
                          </div>
                        </div>
                        </div>
                      <div class="row">
                        <div class="col-md-5"  style="    background-color: #f1f1f1;
                        padding: 15px;
                        height: 250px;
                        overflow-y: scroll;">

                          <div class="techs-div" *ngFor= "let data of techsdata">
                            <div class="row">
                                <div class="col-md-4">
                                  {{data.userName}}
                                </div>
                                <div class="col-md-2">
  :
                                </div>
                                <div class="col-md-3">
                                  <b>{{data.userId}}</b>
                                </div>
                            </div>
                        </div>


                            </div>

                            <div class="col-md-6">
                            <div  style="    background-color: #f1f1f1;
                            padding: 15px;
                            height: 250px;
                            overflow-y: scroll;">

                              <div  class="row" >
                                  <div class="col-md-6">NC-Inspection :
                                    </div>

                                    <div class="col-md-5">
                                      <select     (change)="getTechId($event.target.value,27,'NC-Inspection')" class="form-control">

                                        <option value="" dir="">Select</option>
                                      <option *ngFor= "let data1 of techsdata" value="{{data1.userId}}">{{data1.userName}}</option>

                                  </select>
                                    </div>
                              </div>
                            </div>
                          </div>
                    </div>

                      <div class="form-group clearfix">
                        <a href="javascript:;" class="form-wizard-previous-btn float-left">Previous</a>
                        <button type="submit"  [disabled]="!Inspection.valid" style="float: right;" class="btn  btn-danger text-white text-center" >Submit</button>

                        <!-- <a href="javascript:;" class="form-wizard-submit float-right">Submit</a> -->
                      </div>
                    </fieldset>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
    </div>
    </div>
  </div>


  <div class="modal fade bd-example-modal-lg5" id="demo-7" tabindex="-1" style="overflow:scroll;">
    <div class="modal-dialog modal-dialog-centered appointment_popup_main">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title" id="exampleModalLabel-2"><b>Filters</b></h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>

            </div>

            <div class="modal-body">
              <h6  ><b>Date Range</b></h6>
             <div class="row">

              <div class="col-md-6 form-group">
                <label class="form_row_heading">From<span class="text-danger">*</span></label>
                 <input type="text" placeholder="MM/DD/YYYY" (change)="getStartDate($event.target.value)"
                 onfocus="(this.type='date' )"  id="date" bsDatepicker minDate="today" class="form-control wizard-required" formControlName="dropOffDate" id="dropDate" name="dropDate" #from>


                </div>
                <div class="col-md-6 form-group">
                  <label class="form_row_heading">TO<span class="text-danger">*</span></label>
                   <input type="text" placeholder="MM/DD/YYYY" (change)="getEndDate($event.target.value)"
                   onfocus="(this.type='date' )" id="date" bsDatepicker minDate="today" class="form-control wizard-required" formControlName="dropOffDate" id="dropDate" name="dropDate" #to>


                  </div>
            </div>
             </div>
             <div class="modal-footer">

              <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
             <button type="submit" class="btn btn-success" [disabled]="(from.value.length === 0  || to.value.length === 0  )"   data-dismiss="modal"   (click)="getFilterdData()">Submit</button>
         </div>
        </div>
    </div>
  </div>


   <!-- [ Modal #3 ] -->
   <div class="modal fade" id="demo-3" tabindex="-1">
    <div class="modal-dialog modal-lg" style="margin-left: 250px;">
     <div class="modal-content" style="background-image: url(../../../assets/images/customer_pop2.png);min-height: 250px;">
       <img src="../../../assets/images/customer_pop1.png" alt="pop_animation" class="pop_animation_customer">
       <div class="row">
        <div class="col-md-12" style="padding-top: 15px;
        padding-bottom: 15px;
        padding-left: 32px;">
          <h4 style="text-align: center;font-family: 'Calistoga', cursive;">Customer Information<br>
           <img src="../../../assets/images/customer_underline.png" alt="customer_heading" class="customer_image_underline">
          </h4>

          <button type="button" class="btn btn-danger" data-dismiss="modal"  style="position: absolute;
          right: 24px;border-radius: 50%;
          top: 5px;">&times;</button>
          <button type="button" class="close" data-dismiss="modal"><i class="icon-xs-o-md"></i></button>
        </div>
       </div>
          <div>
            <div class="container">
              <table cellpadding="3" cellspacing="0" border="0" style="width: 100%; margin: 0 auto 2em auto;">
                <tbody class="row">
                    <tr id="filter_cols0" data-column="0" class="col-md-4">
                      <label>First Name </label>
                      <input type="text" class="column_filter form-control" id="cols0_filter">

                    </tr>
                    <tr id="filter_cols1" data-column="1" class="col-md-4">
                      <label>Last Name </label>
                      <input type="text" class="column_filter form-control" id="cols1_filter">
                          </tr>
                    <tr id="filter_cols2" data-column="2" class="col-md-4">
                      <label>Phone Number </label>
                      <input type="text" class="column_filter form-control" id="cols2_filter">
                          </tr>
                </tbody>
            </table>
            <div>


            <div class="table-responsive" id="showDivs" style="width: 100%;height:300px;display:none;margin-bottom:75px;">
                <table class="table table-striped table-bordered" id="examplepopups" *ngIf="showTable" style="width:100%;">
                    <thead>
                        <tr>
                        <th>First Name</th>
                        <th>Last Name</th>
                        <th>Phone Number</th>
                        <th>Action</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor= "let completeds of datacompleted">

                     <td>{{completeds.firstName}}</td>
                     <td>{{completeds.lastName}}</td>
                     <td>{{completeds.phoneNo}}</td>
                     <td>
                      <button type="button" class="btn btn-primary text-white text-center" data-toggle="modal"
                      data-target="#demo-6" data-dismiss="modal" (click)="GetAppointmentByIdExstingForInspections(completeds.appointmentId)">Select</button>
                  </td>

                    </tr>
                    </tbody>
                </table>

            </div>
            </div>
          </div>
     </div>
    </div>
  </div>

</div>

<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait. </p>
  </ngx-spinner>
