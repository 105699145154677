import { Component, OnInit } from '@angular/core';
import { GetappointmentsService } from '../../../getappointments.service';
import { take } from 'rxjs/operators';
import { DashboardData } from '../dashboardhome/dashboard.model';
import {Chart} from 'chart.js';
import { ChartDataSets, ChartType, ChartOptions } from 'chart.js';
import { Label } from 'ng2-charts';
import { NgxSpinnerService } from "ngx-spinner";
import {FormBuilder,FormGroup,Validators,FormControl,} from '@angular/forms';
import{ServicesTechnicionsArray} from '../appointments/ServicesTechnicionsArray'
import{BindservicesTechsArray} from'../appointments/BindservicesTechsArray'
import swal from 'sweetalert2';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-dashboardhome',
  templateUrl: './dashboardhome.component.html',
  styleUrls: ['./dashboardhome.component.scss'],
})
export class DashboardhomeComponent implements OnInit {

  private monthNames: Array<string> = [];
  private totalAppiontmentcount = [];
  private monthwiseReviewRemaindercount:Array<number> = [];
  private monthwiseServiceRemaindercount:Array<number> = [];
  private totalReviewCount:Array<number> = [];


  [x: string]: any;
  walkinUpdateForm: FormGroup;
 
  selectedItemsForService:[]
  selectedItemsforTech: ServicesTechnicionsArray []=[];
  bindSelected: BindservicesTechsArray []=[];
  sData :[]
    data=[];
    gettodayandinprogressappointmentsData :[];
    datacompleted = [];
    makesdata: [];
    modelsdata:[];
    Servicesdata:  [];
    techsdata:[];
   // graphRequest : number;
    graphRequest1 : number;
    graphRequest2 : number;
    graphRequest3 : number;
    graphRequest4 : number;

   
    
  
    public visitSaleChartOptions1: ChartOptions;
  


  
    public visitSaleChartOptions2: ChartOptions;
  

 
    public visitSaleChartOptions3: ChartOptions;
    public visitSaleChartOptions4: ChartOptions;
   


    carInspectionList:[];
    public dtOptions: any = {};
    public dtOptionsone: any = {};
    pipe = new DatePipe('en-US');
    now = Date.now();

  public appointment: any = {
    appointmentId: 0,
    firstName: '',
    lastName: '',
    phoneNo: '',
    email:'',
    vechileBrand:'',
    vechileModel: '',
    vechileManufacturingYear:'',
    preferredContact:'',
    dropOffDate:'',
    dropOffTime:'',
    serviceRequestedMessage:'',
    vehicleIdentificationNumber:'',
    status:'',
    mileage:0,
    customerId:0,
    clientId:0,
    reviewStatus:'',
    createdOn:'',
    reviewRemindCount:0,
    serviceRemindCount:0,
    services:'',
    comments:[''],
    appointmentServices:[],
 appointmentFrom:''
  };

  
  barChartOptions: ChartOptions = {
    responsive: true,
    scales: { xAxes: [{}], yAxes: [{ ticks: {
      min: 0,
      max: 300,
    }}] },
  };
  barChartLabels: Label[] = [];
  barChartType: ChartType = 'bar';
  barChartLegend = true;
  barChartPlugins = [];
  barChartData: ChartDataSets[] = [
    { data: [10, 20, 30, 50, 700, 80, 100], label: 'Company A' },
  ];

  public dashboardData: DashboardData = {
    totalcustomersCount: 0,
    totalReviewCount: 0,
    serviceReminderCount: 0,
    reviewReminderCount: 0,
    monthWiseReviewsAndAppointment:[],
  };
  

  barchart: any;
  month: any;
  constructor(private user: GetappointmentsService,private SpinnerService: NgxSpinnerService,private fb: FormBuilder) {
    this.walkinUpdateForm = this.fb.group({
      appointmentId: [''],
      firstName: ['',Validators.compose([Validators.required, Validators.minLength(3),Validators.maxLength(50)])],
      lastName: [''],
      phoneNo: ['',[Validators.required, Validators.pattern("^((\\+91-?)|0)?[0-9]{10}$")]],
      email:['',Validators.compose([Validators.required,Validators.email,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")])],
      vechileBrand: ['',Validators.required],
      vechileModel: ['',Validators.required],
      vechileManufacturingYear: ['',Validators.required],
      preferredContact: ['',Validators.required],
      dropOffDate :['',Validators.required],
      dropOffTime: ['',Validators.required],
      status: [''],
      customerId:[''],
      createdOn:[''],
      clientId: [''],
      reviewRemindCount:[''],
      // serviceRemindCount:[''],
      reviewStatus: [''],
      appointmentFrom:'',
      mileage:[0, [Validators.required, Validators.pattern("^[0-9]*$")]],
      comments:[''],
      VehicleIdentificationNumber:['',Validators.compose([Validators.required, Validators.minLength(17)])],
      services:[this.selectedItemsForService]
    });


  
    
  }

   
  getAllServices()
   {
    this.user
    .getServices()
    .pipe(take(1))
    .subscribe((data: any) => {
  
      this.Servicesdata = data;
     
    });
  }
getAllTechnicions()
{
  this.user
  .getTechnicions( )
  .pipe(take(1))
  .subscribe((data: any) => {

    this.techsdata = data;
   
  });
}
   getAllMakes()
   {
    this.user
    .getmake()
    .pipe(take(1))
    .subscribe((data: any) => {

      this.makesdata = data;
 
    });
   }
  getModels( id :number)
   {
     this.SpinnerService.show()
     this.user
     .getModelsById(id)
     .pipe(take(1))
     .subscribe((data: any) => {
 
       this.modelsdata = data;
       this.SpinnerService.hide()
      
     });
   }
   AppointmentStatus(status :string,)
{
  if(status==="6")
  {
this.WalkinUpdate();
  }

 else{
  
 }
}
   public VechileStatus(id: string): string{
    switch(id) {
      case "1": {
        return 'Confirmed'
        break;

      }
      case "2": {

       return 'Technician Assigned'
        break;

      }
      case "3": {

  return 'IN-Progress'
        break;

      }
      case "4": {

      return 'Work Done-Technician'
        break;

      }
      case "5": {

        return 'Confirmed by Supervisor'
          break;
  
        }
        case "6": {

          return 'Pickup Scheduled'
            break;
    
          }
          case "7": {

            return 'Cancelled'
              break;
      
            }
            case "8": {

              return 'Re-scheduled'
                break;
        
              }
              case "9": {

                return 'Accepted'
                  break;
          
                }
    }

      }
      closepopup() {

        window.location.reload();
      }
 // appointment chat

getchartnumber1(id: number){
  this.graphRequest1 = id;
  this.populateChart1(this.dashboardData);
}
private populateChart1(chartData1: any) {

  const AppointmnetsData = [];
  this.totalAppiontmentcount = this.appointmentsData;
  this.visitSaleChartLabels1 = [];
if(this.graphRequest1==null)
{
this.graphRequest1=3
}

if(this.graphRequest1==3)
{
chartData1.threeMonths.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels1;
  AppointmnetsData.push(_.totalAppiontmentcount);
  this.visitSaleChartLabels1.push(_.monthName+' '+_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}
else if(this.graphRequest1==6)
{
chartData1.sixMonths.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels1;
  AppointmnetsData.push(_.totalAppiontmentcount);
  this.visitSaleChartLabels1.push(_.monthName+' '+_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}
else if(this.graphRequest1==11)
{
chartData1.yeartoDate.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels1;
  AppointmnetsData.push(_.totalAppiontmentcount);
  this.visitSaleChartLabels1.push(_.monthName+' '+_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}
else if(this.graphRequest1==12)
{
chartData1.oneYear.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels1;
  AppointmnetsData.push(_.totalAppiontmentcount);
  this.visitSaleChartLabels1.push(_.monthName+' '+_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}
else if(this.graphRequest1==36)
{
chartData1.threeYears.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels1;
  AppointmnetsData.push(_.totalAppiontmentcount);
  this.visitSaleChartLabels1.push(_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}

else if(this.graphRequest1==40)
{
chartData1.maxYears.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels1;
  AppointmnetsData.push(_.totalAppiontmentcount);
  this.visitSaleChartLabels1.push(_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}


var option = {
  showLines: true,
  legend: {
    display: false
  }
};
  this.visitSaleChartData1 = [
    {
      label: "Appiontments",
      data: AppointmnetsData,
      maxBarThickness:8,
      categoryPercentage: 0.2
    },
    
  
  ];
  this.visitSaleChartOptions1 = {
    responsive: true,
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            drawBorder: true,
            color: "#c4cade", 
            zeroLineColor: "#606c91",
          },
          ticks: {
            padding: 10,
            fontColor: "black",
            fontSize:14,
            beginAtZero : true,
            /*
            min:0,
            stepSize:0.2,
            lineHeight: 1,
            maxTicksLimit:11,
            suggestedMax:100,
            */
           min:0,
           stepSize:0.2,
           lineHeight:1,
           maxTicksLimit:6,
           suggestedMax:100,
          },
        },
        
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: true,
            color: "#606c91",
            zeroLineColor: "rgba(235,237,242,1)",
          },
          ticks: {
            padding: 10,
            fontColor: "black",
            fontSize:14


          },
        },
      ],
    },
  };

  this.visitSaleChartColors1 = [
    { backgroundColor:[ "#3cb371",
    "#0000FF",
    "#9966FF",
    "#4C4CFF",
    "#00FFFF",
    "#f990a7",
    "#aad2ed",
    "#FF00FF",
    "Blue",
    "Red",
    "Blue"],borderColor:"#CFD9DF" },
    { backgroundColor: [ "#3cb371",
    "#0000FF",
    "#9966FF",
    "#4C4CFF",
    "#00FFFF",
    "#f990a7",
    "#aad2ed",
    "#FF00FF",
    "Blue",
    "Red",
    "Blue"],borderColor: "rgba(54, 162, 235, 1)" },
  ];

}


// reminder chat



getchartnumber2(id: number){
  this.graphRequest2 = id;
  this.populateChart2(this.dashboardData);
}
private populateChart2(chartData1: any) {
 
  const serviceRemindersData = [];
  this.monthwiseServiceRemaindercount = this.remindersData;
  this.visitSaleChartLabels2 = [];
if(this.graphRequest2==null)
{
this.graphRequest2=3
}

if(this.graphRequest2==3)
{
chartData1.threeMonths.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels2;
  serviceRemindersData.push(_.monthwiseServiceRemaindercount);
  this.visitSaleChartLabels2.push(_.monthName+' '+_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}
else if(this.graphRequest2==6)
{
chartData1.sixMonths.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels2;
  serviceRemindersData.push(_.monthwiseServiceRemaindercount);
  this.visitSaleChartLabels2.push(_.monthName+' '+_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}
else if(this.graphRequest2==11)
{
chartData1.yeartoDate.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels2;
  serviceRemindersData.push(_.monthwiseServiceRemaindercount);
  this.visitSaleChartLabels2.push(_.monthName+' '+_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}
else if(this.graphRequest2==12)
{
chartData1.oneYear.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels2;
  serviceRemindersData.push(_.monthwiseServiceRemaindercount);
  this.visitSaleChartLabels2.push(_.monthName+' '+_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}
else if(this.graphRequest2==36)
{
chartData1.threeYears.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels2;
  serviceRemindersData.push(_.monthwiseServiceRemaindercount);
  this.visitSaleChartLabels2.push(_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}

else if(this.graphRequest2==40)
{
chartData1.maxYears.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels2;
  serviceRemindersData.push(_.monthwiseServiceRemaindercount);
  this.visitSaleChartLabels2.push(_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}



  this.visitSaleChartData2 = [
    {
      label: "Service Reminders",
      data: serviceRemindersData,
      maxBarThickness:8,
      categoryPercentage: 0.2
    },
  ];
  this.visitSaleChartOptions2 = {
    responsive: true,
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            drawBorder: true,
            color: "#c4cade",
            zeroLineColor: "#606c91",
          },
          
          ticks: {
            padding: 10,
            fontColor: "black",
            fontSize:14,
            beginAtZero : true,
            min:0,
            stepSize:0.2,
            lineHeight:1,
            maxTicksLimit:6,
            suggestedMax:100,

          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: true,
            color: "#606c91",
            zeroLineColor: "rgba(235,237,242,1)",
          },
          ticks: {
            padding: 10,
            fontColor: "black",
            fontSize:14


          },
        },
      ],
    },
  };

  this.visitSaleChartColors2 = [
    { backgroundColor:[ 
      "#4C4CFF",
      "#f990a7",
    "#aad2ed",
    "#FF00FF",
      "#3cb371",
    "#0000FF",
    "#9966FF",
   
    "#00FFFF",
    "Blue",
    "Red",
    "Blue"],borderColor:"#CFD9DF" },
    { backgroundColor: [  "#f990a7",
    "#aad2ed",
    "#FF00FF",
      "#3cb371",
    "#0000FF",
    "#9966FF",
    "#4C4CFF",
    "#00FFFF",
    "Blue",
    "Red",
    "Blue"],borderColor: "rgba(54, 162, 235, 1)" },
  ];

}



getchartnumber3(id: number){
  this.graphRequest3 = id;
  this.populateChart3(this.dashboardData);
}
private populateChart3(chartData1: any) {
  const reviewsData = [];
  this.totalReviewCount = this.reviewData;
  this.visitSaleChartLabels3 = [];
if(this.graphRequest3==null)
{
this.graphRequest3=3
}

if(this.graphRequest3==3)
{
chartData1.threeMonths.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels3;
  reviewsData.push(_.reviewsCounts.totalCount);
  this.visitSaleChartLabels3.push(_.monthName+' '+_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}
else if(this.graphRequest3==6)
{
chartData1.sixMonths.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels3;
  reviewsData.push(_.reviewsCounts.totalCount);
  this.visitSaleChartLabels3.push(_.monthName+' '+_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}
else if(this.graphRequest3==11)
{
chartData1.yeartoDate.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels3;
  reviewsData.push(_.reviewsCounts.totalCount);
  this.visitSaleChartLabels3.push(_.monthName+' '+_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}
else if(this.graphRequest3==12)
{
chartData1.oneYear.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels3;
  reviewsData.push(_.reviewsCounts.totalCount);
  this.visitSaleChartLabels3.push(_.monthName+' '+_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}
else if(this.graphRequest3==36)
{
chartData1.threeYears.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels3;
  reviewsData.push(_.totalReviewCount.totalCount);
  this.visitSaleChartLabels3.push(_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}

else if(this.graphRequest3==40)
{
chartData1.maxYears.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels3;
  reviewsData.push(_.totalReviewCount.totalCount);
  this.visitSaleChartLabels3.push(_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}



  this.visitSaleChartData3 = [
    {
      label: "Reviews",
      data: reviewsData,
      maxBarThickness:8,
      categoryPercentage: 0.2
    },
  ];
  this.visitSaleChartOptions3 = {
    responsive: true,
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            drawBorder: true,
            color: "#c4cade", 
            zeroLineColor: "#606c91",
          },
          ticks: {
            padding: 10,
            fontColor: "black",
            fontSize:14,
            beginAtZero : true,
            min:0,
            stepSize:0.2,
            lineHeight: 1,
            maxTicksLimit:6,
            suggestedMax:100,

          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: true,
            color: "#606c91",
            zeroLineColor: "rgba(235,237,242,1)",
          },
          ticks: {
            padding: 10,
            fontColor: "black",
            fontSize:14


          },
        },
      ],
    },
  };

  this.visitSaleChartColors3 = [
    { backgroundColor:[ "#f990a7", "Red","#3cb371",
    "#0000FF",
    "#9966FF",
    "#4C4CFF",
    "#00FFFF",
    
    "#aad2ed",
    "#FF00FF",
    "Blue",
    "Blue"],borderColor:"#CFD9DF" },
    { backgroundColor: [  "Red","#3cb371",
    "#0000FF",
    "#9966FF",
    "#4C4CFF",
    "#00FFFF",
    "#f990a7",
    "#aad2ed",
    "#FF00FF",
    "Blue",
    "Blue"],borderColor: "rgba(54, 162, 235, 1)" },
  ];

}

getchartnumber4(id: number){
  this.graphRequest4 = id;
  this.populateChart4(this.dashboardData);
}
private populateChart4(chartData1: any) {
  const reviewsRemindersData = [];
  this.monthwiseReviewRemaindercount = this.reviewReminderData;
  this.visitSaleChartLabels4 = [];
if(this.graphRequest4==null)
{
this.graphRequest4=3
}

if(this.graphRequest4==3)
{
chartData1.threeMonths.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels4;
  reviewsRemindersData.push(_.monthwiseReviewRemaindercount);
  this.visitSaleChartLabels4.push(_.monthName+' '+_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}
else if(this.graphRequest4==6)
{
chartData1.sixMonths.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels4;
  reviewsRemindersData.push(_.monthwiseReviewRemaindercount);
  this.visitSaleChartLabels4.push(_.monthName+' '+_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}
else if(this.graphRequest4==11)
{
chartData1.yeartoDate.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels4;
  reviewsRemindersData.push(_.monthwiseReviewRemaindercount);
  this.visitSaleChartLabels4.push(_.monthName+' '+_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}
else if(this.graphRequest4==12)
{
chartData1.oneYear.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels4;
  reviewsRemindersData.push(_.monthwiseReviewRemaindercount);
  this.visitSaleChartLabels4.push(_.monthName+' '+_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}
else if(this.graphRequest4==36)
{
chartData1.threeYears.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels4;
  reviewsRemindersData.push(_.monthwiseReviewRemaindercount);
  this.visitSaleChartLabels4.push(_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}

else if(this.graphRequest4==40)
{
chartData1.maxYears.forEach((_) => {
  this.monthNames = this.visitSaleChartLabels4;
  reviewsRemindersData.push(_.monthwiseReviewRemaindercount);
  this.visitSaleChartLabels4.push(_.year);
//});
// chartData.yearWiseCustomers.forEach((_) => {
});
}



  this.visitSaleChartData4 = [
    {
      label: "Reviews Reminders",
      data: reviewsRemindersData,
      maxBarThickness:8,
      categoryPercentage: 0.2
    },
  ];
  this.visitSaleChartOptions4 = {
    responsive: true,
    legend: {
      display: false
    },
    scales: {
      yAxes: [
        {
          gridLines: {
            drawBorder: true,
            color: "#c4cade", 
            zeroLineColor: "#606c91",
          },
          ticks: {
            padding: 10,
            fontColor: "black",
            fontSize:14,
            beginAtZero : true,
            min:0,
            stepSize:0.2,
            lineHeight:1,
            maxTicksLimit:6,
            suggestedMax:100,

          },
        },
      ],
      xAxes: [
        {
          gridLines: {
            display: false,
            drawBorder: true,
            color: "#606c91",
            zeroLineColor: "rgba(235,237,242,1)",
          },
          ticks: {
            padding: 10,
            fontColor: "black",
            fontSize:14


          },
        },
      ],
    },
  };

  this.visitSaleChartColors4 = [
    { backgroundColor:[ " #22ffff", "#3cb371",
    "#0000FF",
    "#9966FF",
    "#4C4CFF",
    "#00FFFF",
    "#f990a7",
    "#aad2ed",
    "#FF00FF",
    "Blue",
    "Red",
    "Blue"],borderColor:"#CFD9DF" },
    { backgroundColor: [ "#3cb371",
    "#0000FF",
    "#9966FF",
    "#4C4CFF",
    "#00FFFF",
    "#f990a7",
    "#aad2ed",
    "#FF00FF",
    "Blue",
    "Red",
    "Blue"],borderColor: "rgba(54, 162, 235, 1)" },
  ];

}







   // view appoinments
   GetAppointmentviewById( appointmentId :number)
   {
     this.SpinnerService.show();
     this.user
     .getAppointmentById(appointmentId)
     .pipe(take(1))
     .subscribe((data: any) => {
       // this.selectedItemsForService=this.appointment.appointmentServices.appointmentId;
//console.log(this.selectedItemsForService);
 
       this.appointment = data;
       this.SpinnerService.hide();
      
     });
   }
//get Appointment by Id
GetAppointmentById( appointmentId :number)
{
  this.appointment = null;
  this.SpinnerService.show();
  this.user
  .getAppointmentById(appointmentId)
  .pipe(take(1))
  .subscribe((data: any) => {
    
    this.appointment = data;
    let  dateFormat = this.pipe.transform(this.appointment.dropOffDate, 'yyyy-MM-dd');
    this.walkinUpdateForm.controls['firstName'].setValue(this.appointment.firstName);
    this.walkinUpdateForm.controls['lastName'].setValue(this.appointment.lastName);
    this.walkinUpdateForm.controls['phoneNo'].setValue(this.appointment.phoneNo);
    this.walkinUpdateForm.controls['email'].setValue(this.appointment.email);
    this.walkinUpdateForm.controls['vechileBrand'].setValue(this.appointment.vechileBrand);
    this.walkinUpdateForm.controls['vechileModel'].setValue(this.appointment.vechileModel);
    this.walkinUpdateForm.controls['vechileManufacturingYear'].setValue(this.appointment.vechileManufacturingYear);
    this.walkinUpdateForm.controls['preferredContact'].setValue(this.appointment.preferredContact);
    this.walkinUpdateForm.controls['dropOffDate'].setValue(dateFormat);
    this.walkinUpdateForm.controls['dropOffTime'].setValue(this.appointment.dropOffTime);
    this.walkinUpdateForm.controls['status'].setValue(this.appointment.status);
    this.walkinUpdateForm.controls['appointmentId'].setValue(this.appointment.appointmentId);
    this.walkinUpdateForm.controls['customerId'].setValue(this.appointment.customerId);
    this.walkinUpdateForm.controls['clientId'].setValue(this.appointment.clientId);
    this.walkinUpdateForm.controls['reviewRemindCount'].setValue(this.appointment.reviewRemindCount);
    // this.walkinUpdateForm.controls['serviceRemindCount'].setValue(this.appointment.serviceRemindCount);
    this.walkinUpdateForm.controls['reviewStatus'].setValue(this.appointment.reviewStatus);
    this.walkinUpdateForm.controls['createdOn'].setValue(this.appointment.createdOn);
    this.walkinUpdateForm.controls['mileage'].setValue(this.appointment.mileage);
    this.walkinUpdateForm.controls['VehicleIdentificationNumber'].setValue(this.appointment.vehicleIdentificationNumber);
    this.walkinUpdateForm.controls['appointmentFrom'].setValue(this.appointment.appointmentFrom);
    this.walkinUpdateForm.controls['comments'].setValue(this.appointment.comments);

   // this.bindSelected= [];
this.bindSelected.length = 0;

    if(this.appointment.appointmentServices!==null)
    {
      this.appointment.appointmentServices.forEach(element => {
        let selectedService: any = this.Servicesdata.find((_: any) => _.serviceId === element.serviceId);
        let techName: any = this.techsdata.find((_: any) => _.userId ===element.technicianId);
        selectedService.isCheched = true;
        this.bindSelected.push({ServiceId:selectedService.serviceId,TechnicianId:techName.userId,TechnicianName:techName.userName,ServiceName:selectedService.serviceName,RemoveId:''});
      
      });
      
    }
    this.SpinnerService.hide();
   
  });
}
  getserviceId(e:any, id:number,servicename:string){

    if(e.target.checked){
         
          
       
          this.selectedItemsforTech.push({ServiceId: id, TechnicianId:0,TechnicianName:'',ServiceName:servicename,RemoveId:id.toString()}); //to add when checked
          this.bindSelected.push({ServiceId: id,TechnicianId:0,TechnicianName:'', ServiceName: servicename,RemoveId:id.toString()}); //to  add item when checked and adding  serviceid as string in RemoveId


    }else{
     
     

   // this.selectedItemsForService = this.selectedItemsForService.filter(item => item.ServiceId !== id);//to remove when uncheked
    this.selectedItemsforTech = this.selectedItemsforTech.filter(item => item.ServiceId !== id); // to remove unchked item when technicion changed
    this.bindSelected = this.bindSelected.filter(item => item.ServiceId !== id);//to remove service when uncheked
 
    }

   /// console.log(this.selectedItemsForService);
   

  }
  getTechId(technitionId :string,serviceId:number,serviceName:string)
  {
    
  
  if (this.selectedItemsforTech.find((x: ServicesTechnicionsArray) => x.ServiceId === serviceId)) {
   
     this.selectedItemsforTech = this.selectedItemsforTech.filter(item => item.RemoveId !== serviceId.toString());//if service id already exist then to update technicion id remove  this item
     this.selectedItemsforTech = this.selectedItemsforTech.filter(item => item.ServiceId !== serviceId);//if service id already exist then to update technicion id remove  this item
    
   }

    let techName: any = this.techsdata.find((_: any) => _.userId.toString() ===technitionId);
  
    
   
   this.selectedItemsforTech.push({ServiceId:serviceId , TechnicianId : parseInt (technitionId), TechnicianName:techName.userName,ServiceName:serviceName,RemoveId:''});//add  same item deleted above   with new techncion id
 
  }

  getTechId2(technitionId :string,serviceId:number,serviceName:string,TechnicianName:string)
  {
    if (this.bindSelected.find((x: BindservicesTechsArray) => x.ServiceId === serviceId)) {
   
      this.bindSelected = this.bindSelected.filter(item => item.ServiceId !== serviceId);//if service id already exist then to update technicion id remove  this item
     
    }
    this.bindSelected = this.bindSelected.filter(item => item.RemoveId !== serviceId.toString());//to remove same service having serviceId string //double services so deleting 1 having string
    let techName: any = this.techsdata.find((_: any) => _.userId.toString() ===technitionId);
    this.bindSelected.push({ServiceId:serviceId , TechnicianId :parseInt(technitionId) ,TechnicianName:techName.userName,ServiceName:serviceName,RemoveId:''});//
    
   
   
  
   
  }
  public get walkinUpdateFormControl() { return this.walkinUpdateForm.controls; }

  WalkinUpdate() : void{
    this.SpinnerService.show();
    const data = {
      appointmentId: this.walkinUpdateFormControl["appointmentId"].value,
      firstName: this.walkinUpdateFormControl["firstName"].value,
      lastName: this.walkinUpdateFormControl["lastName"].value,
      phoneNo: this.walkinUpdateFormControl["phoneNo"].value,
      email: this.walkinUpdateFormControl["email"].value,
      vechileBrand: this.walkinUpdateFormControl["vechileBrand"].value,
      vechileModel: this.walkinUpdateFormControl["vechileModel"].value,
      vechileManufacturingYear: this.walkinUpdateFormControl["vechileManufacturingYear"].value,
      preferredContact: this.walkinUpdateFormControl["preferredContact"].value,
      dropOffDate :this.walkinUpdateFormControl["dropOffDate"].value,
      dropOffTime :this.walkinUpdateFormControl["dropOffTime"].value,
      AssignTechnicians: this.bindSelected,
      status: this.walkinUpdateFormControl["status"].value,
      mileage: this.walkinUpdateFormControl["mileage"].value,
      VehicleIdentificationNumber: this.walkinUpdateFormControl["VehicleIdentificationNumber"].value,
      customerId: this.walkinUpdateFormControl["customerId"].value,
      clientId: this.walkinUpdateFormControl["clientId"].value,
      reviewRemindCount: this.walkinUpdateFormControl["reviewRemindCount"].value,
      // serviceRemindCount :this.walkinUpdateFormControl["serviceRemindCount"].value,
      reviewStatus :this.walkinUpdateFormControl["reviewStatus"].value,
      AppointmentFrom :this.walkinUpdateFormControl["appointmentFrom"].value,
      Comments :this.walkinUpdateFormControl["comments"].value,
      createdOn:this.walkinUpdateFormControl["createdOn"].value,
      }
  console.log(data,"dashboard update");
    if (data) {
      this.user
         .WalkinAppointmentAddandUpdate(data)
         .pipe(take(1))
        .subscribe((res: any) => {
           if (res.statusCode=== 1){
            this.SpinnerService.hide();
            // this.user.getallactiveappointments().subscribe((data: any) => {
            //   console.warn(data)
            // this.getallactiveappointmentsdata =data;
            // this.showTable=true;
            // })
         
            
             swal.fire(
              'Success',
              'Appointment Updated Succesfully.',
            'success'
         );
  
       //  (<any>$("#demo_update")).modal('hide');
         
             this.walkinUpdateForm.reset();
             window.location.reload();
           
          }
          else{
            if (res.statusCode!==1){
              swal.fire(
                'Cancelled!',
                'Please enter valid details',
                'error'
              );
             // window.alert('Please enter valied details');
         }
       }
        });
     }
    }
    public ngOnInit(): void {
      this.SpinnerService.show();
      this.user.getClientId();
      this.getAllMakes();
      this.getAllServices();
      this.getAllTechnicions();
      this.user
        .dashboarddata()
        .pipe(take(1))
        .subscribe((data: DashboardData) => {
          this.dashboardData = data;
       console.log( this.dashboardData);
    
       this.populateChart1(this.dashboardData);
       this.populateChart2(this.dashboardData);
       this.populateChart3(this.dashboardData);
       this.populateChart4(this.dashboardData);
       this.getchartnumber2(3);
        });
  
      // APPOINMENT TABLE GET CALL
        this.user.gettodayandinprogressappointments().subscribe((data: any) => {
         
          this.gettodayandinprogressappointmentsData=data;
          this.SpinnerService.hide();
        })
  
        
       
        jQuery(document).ready(function() {
          // click on next button
          jQuery('.form-wizard-next-btn').click(function() {
            var parentFieldset = jQuery(this).parents('.wizard-fieldset');
            var currentActiveStep = jQuery(this).parents('.form-wizard').find('.form-wizard-steps .active');
            var next = jQuery(this);
            var nextWizardStep = true;
            parentFieldset.find('.wizard-required').each(function(){
              var thisValue = jQuery(this).val();
        
              if( thisValue == "") {
                jQuery(this).siblings(".wizard-form-error").slideDown();
                nextWizardStep = false;
              }
              else {
                jQuery(this).siblings(".wizard-form-error").slideUp();
              }
            });
            if( nextWizardStep) {
              next.parents('.wizard-fieldset').removeClass("show");
              currentActiveStep.removeClass('active').addClass('activated').next().addClass('active');
              next.parents('.wizard-fieldset').next('.wizard-fieldset').addClass("show");
              jQuery(document).find('.wizard-fieldset').each(function(){
                if(jQuery(this).hasClass('show')){
                  var formAtrr = jQuery(this).attr('data-tab-content');
                  jQuery(document).find('.form-wizard-steps .form-wizard-step-item').each(function(){
                    if(jQuery(this).attr('data-attr') == formAtrr){
                      jQuery(this).addClass('active');
                      var innerWidth = jQuery(this).innerWidth();
                      var position = jQuery(this).position();
                      jQuery(document).find('.form-wizard-step-move').css({"left": position.left, "width": innerWidth});
                    }else{
                      jQuery(this).removeClass('active');
                    }
                  });
                }
              });
            }
          });
          //click on previous button
          jQuery('.form-wizard-previous-btn').click(function() {
            var counter = parseInt(jQuery(".wizard-counter").text());;
            var prev =jQuery(this);
            var currentActiveStep = jQuery(this).parents('.form-wizard').find('.form-wizard-steps .active');
            prev.parents('.wizard-fieldset').removeClass("show");
            prev.parents('.wizard-fieldset').prev('.wizard-fieldset').addClass("show");
            currentActiveStep.removeClass('active').prev().removeClass('activated').addClass('active');
            jQuery(document).find('.wizard-fieldset').each(function(){
              if(jQuery(this).hasClass('show')){
                var formAtrr = jQuery(this).attr('data-tab-content');
                jQuery(document).find('.form-wizard-steps .form-wizard-step-item').each(function(){
                  if(jQuery(this).attr('data-attr') == formAtrr){
                    jQuery(this).addClass('active');
                    var innerWidth = jQuery(this).innerWidth();
                    var position = jQuery(this).position();
                    jQuery(document).find('.form-wizard-step-move').css({"left": position.left, "width": innerWidth});
                  }else{
                    jQuery(this).removeClass('active');
                  }
                });
              }
            });
          });
          //click on form submit button
          jQuery(document).on("click",".form-wizard .form-wizard-submit" , function(){
            var parentFieldset = jQuery(this).parents('.wizard-fieldset');
            var currentActiveStep = jQuery(this).parents('.form-wizard').find('.form-wizard-steps .active');
            parentFieldset.find('.wizard-required').each(function() {
              var thisValue = jQuery(this).val();
              if( thisValue == "" ) {
                jQuery(this).siblings(".wizard-form-error").slideDown();
              }
              else {
                jQuery(this).siblings(".wizard-form-error").slideUp();
              }
            });
          });
          // focus on input field check empty or not
          jQuery(".form-control").on('focus', function(){
            var tmpThis = jQuery(this).val();
            if(tmpThis == '' ) {
              jQuery(this).parent().addClass("focus-input");
            }
            else if(tmpThis !='' ){
              jQuery(this).parent().addClass("focus-input");
            }
          }).on('blur', function(){
            var tmpThis = jQuery(this).val();
            if(tmpThis == '' ) {
              jQuery(this).parent().removeClass("focus-input");
              jQuery(this).siblings('.wizard-form-error').slideDown("3000");
            }
            else if(tmpThis !='' ){
              jQuery(this).parent().addClass("focus-input");
              jQuery(this).siblings('.wizard-form-error').slideUp("3000");
            }
          });
        });
        
        // this.today=new Date();
        // this.getalldata();
        // this.today=new Date();
    
        (<any>$(document)).ready(function() {
          function filterColumn ( i: string ) {
            $('#examplepopup').DataTable().column( i ).search(
              (<any>$('#col'+i+'_filter')).val(),
              (<any>$('#col'+i+'_smart')).val(),
            ).draw();
        }
            $('#examplepopup').DataTable();
            $('input.column_filter').on( 'keyup click', function () {
                filterColumn( $(this).parents('tr').attr('data-column') );
            } );
        } );
          $("#col0_filter").keyup(function(){
            if($(this).val()) {
                $("#showDiv").show();
            } else {
                $("#showDiv").hide();
            }
        });
        $("#col1_filter").keyup(function(){
          if($(this).val()) {
              $("#showDiv").show();
          } else {
              $("#showDiv").hide();
          }
      });
      $("#col2_filter").keyup(function(){
        if($(this).val()) {
            $("#showDiv").show();
        } else {
            $("#showDiv").hide();
        }
      });
    
      $(document).ready(function(){
        if(  $('#examplepopup tr').length >= 5 ) {
             $('#examplepopup').addClass('add-scroll');
         }
       });
    
      $(document).ready(function(){
        (<any>$("#smartwizard"))
        .smartWizard({
        selected: 0,
        theme: 'dots',
        autoAdjustHeight:false,
        transitionEffect:'fade',
        showStepURLhash: false,
    
        });
        });
    
        $('.modal #demo-4').on('hidden.bs.modal', function(){
          $(this).find('form')[0].reset();
      });
    
        $('.modal').on('hidden.bs.modal', function(){
          $(this).find('form')[0].reset();
      });
    
        $('.modal demo_update').on('hidden.bs.modal', function(){
          $(this).find('form')[0].reset();
      });
    
      $('.modal #demo-2').on('hidden.bs.modal', function(){
          $(this).find('form')[0].reset();
      });
    
       // this.SpinnerService.show();
        this.dtOptions = {
          pagingType: 'full_numbers',
          pageLength: 5,
        lengthMenu : [ 5, 10, 25, 50, 100],
        order: [[ 0, 'desc' ], [ 1, 'desc' ]],
          processing: true,
          dom:
          "<'row'<'col-sm-6'i><'col-sm-6'f>>" +
        "<'row'<'col-sm-12'tr>>" +
        "<'row'<'col-sm-4'l><'col-sm-8'p>>" +
        "<'row'<'col-sm-6'B>>",
    
          //"sDom": 'Rfrt<lipB>',
          // dom:'frtlipB',
          buttons: [
               'excel', 'pdf', 'print'
    
          ]
        };
      
      
      //this.selectedItemsForService = new Array<selectedServices>();
    
      this.selectedItemsforTech = new Array<ServicesTechnicionsArray>();
      this.bindSelected = new Array<BindservicesTechsArray>();
      
      }
    
      smartWizard(arg0: { selected: 0; theme: 'dots'; autoAdjustHeight: false; transitionEffect: 'fade'; showStepURLhash: false; }) {
        throw new Error('Method not implemented.');
      }
    }
    
  

