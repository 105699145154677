import { Component, OnInit } from '@angular/core';
import {CustomersService} from '../../../app/customers.service';
import {CustomerData} from './customerss'
import { take } from 'rxjs/operators';
import { NgxSpinnerService } from "ngx-spinner";
@Component({
  selector: 'app-customers',
  templateUrl: './customers.component.html',
  styleUrls: ['./customers.component.css']
})
export class CustomersComponent implements OnInit {
  public dtOptions: any = {};
  public showTable: boolean = false;
  customerData: Array<CustomerData>=[]
  customerHistory:[];
  startDate:string;
  endDate:string;
  customerFilter1: string;
  customerFilter2: string;
  constructor(private user:CustomersService,private SpinnerService: NgxSpinnerService) {

  
 }
getCustomers()
{
  this.showTable = false;
  this.user.getdata().pipe(take(1)).subscribe((data:any)  =>{
    console.warn(data)
    this.customerData=data;
  
    this.showTable = true;
    this.customerFilter1="hide";
    this.customerFilter2="show";
    this.SpinnerService.hide();
    /*
    let xyz = document.getElementById("xyz").innerHTML;
    document.getElementById("xyz").innerHTML =
    xyz.replace("-", "").replace("-", "");
    console.log(xyz)
    */
    // this.user.getdata().pipe(take(1)).subscribe((data:Array<CustomerData>)  =>{
    //   console.warn(data)
    //   this.customerData=<any[]>data;
    //   this.showTable = true;

});
}

 GetAppointmentsByCustomerId(customerId :number):void {
  this.user.getCustomerAppointmentHistory(customerId).pipe(take(1)).subscribe((data:any) =>{
    this.customerHistory=data;
    this.showTable = true;
    console.log(data);
  })
}
 public ngOnInit(): void {
  this.customerFilter1="hide";
  this.customerFilter2="show";
  this.getCustomers();
  this.SpinnerService.show();

  this.dtOptions = {
    pagingType: 'full_numbers',
    pageLength: 5,
  lengthMenu : [ 5, 10, 25, 50, 100],
    processing: true,
    dom:
    "<'row'<'col-sm-6'i><'col-sm-6'f>>" +
  "<'row'<'col-sm-12'tr>>" +
  "<'row'<'col-sm-4'l><'col-sm-8'p>>" +
  "<'row'<'col-sm-6'B>>",
  buttons: [
    'excel', 'print',
{
 extend:'pdfHtml5',
 text:'Pdf',
 orientation:'landscape',
 
}],
  };
  }

  getStartDate(startDate :string,)
  {
    this.startDate=startDate;

   
  }

  getEndDate(endDate :string,)
  {
    this.endDate=endDate;

   
  }
   //get back to customers from filters 
   customerfromFilter()
   {
    this.customerFilter1="hide";
    this.customerFilter2="show";
       (<any>$("#demo-7")).modal('hide');
     this.getCustomers();
   }
  getFilterdData( )
  {
    this.SpinnerService.show();
    this.showTable=false;
    this.user
    .getFilterdDataCustomers(this.startDate,this.endDate)
    .pipe(take(1))
    .subscribe((data: any) => {
      this.showTable=true;
      this.customerFilter1="show";
      this.customerFilter2="hide";
      this.customerData=data;
      this.SpinnerService.hide();
      console.log(data);
    });
  }
}
