
 <!-- ------### navbar ###------ -->
<app-navbar></app-navbar>

<!-- ------### Main Div Start Here ###------ -->
<div class="row mainrow_margin">
   <div class="col-md-2">
     <!-- ------### Sidenav ###------ -->
    <app-sidenav></app-sidenav>
   </div>
   <div class="col-md-10">
    <!-- ------### right Section starts ###------ -->
<div class="main_section">
   <h4>Customer Details</h4>
  <button  *ngIf="customerFilter2 == 'show'" type="button" class="btn fliter_button" data-toggle="modal" data-target="#demo-7">
    Filter <i class="fa fa-filter" aria-hidden="true"></i>
  </button>

    <div>
      <button type="button"  (click)= customerfromFilter() *ngIf="customerFilter1 == 'show'" class="btn clearfilter">Clear Filter <i class="fa fa-filter" aria-hidden="true"></i></button>
    </div>
</div>

<div class="container">
    <div class="table-responsive">
      <table class="table table-striped table-bordered" *ngIf="showTable" datatable [dtOptions]="dtOptions"  style="width:100%;">
        <thead class="thead-dark">
          <tr>
           <!-- <th>S.No</th> -->
            <th>First <br />Name</th>
            <th>Last <br />Name</th>
            <th>Mobile </th>
            <th>Email </th>
            <th>Year </th>
            <th>Make</th>
            <th>Model</th>
            <th>History</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor= "let customerdatas of customerData">
         <td>{{customerdatas.firstName}}</td>
         <td>{{customerdatas.lastName}}</td>
         <td id="xyz" style="width: 200px;">{{customerdatas.phoneNo}}</td>
         <td>{{customerdatas.email}}</td>
         <td>{{customerdatas.vechileManufacturingYear}}</td>
         <td>{{customerdatas.vechileBrand}}</td>
         <td>{{customerdatas.vechileModel}}</td>
         <td><button class="btn btn-primary viewbutton" (click)="GetAppointmentsByCustomerId(customerdatas.customerId)"
          data-toggle="modal" data-target="#demo-1">View </button></td>
        </tr>
        </tbody>
    </table>
</div>
</div>


</div>
</div>



<!-- ------### Customer Services History ###------ -->
<div class="modal fade" id="demo-1" tabindex="-1">
  <div class="modal-dialog modal-xl">
    <div class="modal-content">
      <div class="modal-body">
        <button type="button" class="btn btn-danger closebutton" data-dismiss="modal">
        <i class="fa fa-times" aria-hidden="true"></i></button>
        <div>
          <h4>Customer Services History</h4>
        </div>

        <div class="table-responsive">
          <table class="table table-striped table-bordered"  style="width:100%;">
            <thead class="thead-dark">
              <tr>
               <th>Ticket <br />Id</th>
                <th>First<br> Name</th>
                <th>Last <br>Name</th>
                <th>Mobile </th>
                <th>Email </th>
                <th>Year </th>
                <th>Make</th>
                <th>Model</th>
                <th>Last <br />Serviceon</th>
                <th>Services <br />Done</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor= "let data of customerHistory">
                <td>{{data.appointmentId}}</td>
                <td>{{data.firstName}}</td>
              <td>{{data.lastName}}</td>
              <td>{{data.phoneNo}}</td>
              <td>{{data.email}}</td>
              <td>{{data.vechileManufacturingYear}}</td>
              <td>{{data.vechileBrand}}</td>
              <td>{{data.vechileModel}}</td>
              <td>{{data.dropOffDate | date:'yyyy-MM-dd'}}</td>
              <td>{{data.serviceRequested}},{{data.additionalServices}}</td>
             </tr>
            </tbody>
        </table>
    </div>
      </div>
      </div>
  </div>
  </div>
<ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
  <p style="color: white">Please Wait. </p>
  </ngx-spinner>

  <!-- ------### Customer Filters ###------ -->
  <div class="modal fade bd-example-modal-lg5" id="demo-7" tabindex="-1" style="overflow:scroll;">
    <div class="modal-dialog modal-dialog-centered appointment_popup_main">
        <div class="modal-content">
            <div class="modal-header">
                <h6 class="modal-title" id="exampleModalLabel-2">Filters</h6>
                <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
              <h6><b>Date Range</b></h6>
             <div class="row">
              <div class="col-md-6 form-group">
                <label class="form_row_heading">From<span class="text-danger">*</span></label>
                 <input type="text" placeholder="MM/DD/YYYY" (change)="getStartDate($event.target.value)"
                 onfocus="(this.type='date' )"  id="date" bsDatepicker minDate="today" class="form-control wizard-required" formControlName="dropOffDate"  name="dropDate" #StartDate>


                </div>
                <div class="col-md-6 form-group">
                  <label class="form_row_heading">To<span class="text-danger">*</span></label>
                   <input type="text" placeholder="MM/DD/YYYY" (change)="getEndDate($event.target.value)"
                   onfocus="(this.type='date' )" id="date" bsDatepicker minDate="today" class="form-control wizard-required" formControlName="dropOffDate" name="dropDate" #EndDate>
                  </div>
            </div>
             </div>
             <div class="modal-footer">
              <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
             <button type="submit" class="btn btn-success"  [disabled]="(StartDate.value.length === 0  || EndDate.value.length === 0  )" data-dismiss="modal"   (click)="getFilterdData()">Submit</button>
         </div>
        </div>
    </div>
  </div>
