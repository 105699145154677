<app-navbar></app-navbar>
<div class="row mainrow_margin">
   <div class="col-md-2">
    <app-sidenav></app-sidenav>
   </div>
   <div class="col-md-10">
    <div class="row" style="margin:3%;">
      <h4 style="margin-left:-10px;font-weight: 600;
      font-size: 22px;
      font-family: 'Poppins';"> Enquiry From {{userName}} Website</h4>
       <br>

<div class="container" style="margin-left:-25px;">
  <button type="button"  *ngIf="requestFilter1 == 'show'" class="btn clearfilter" data-toggle="modal" data-target="#demo-7">Filter <i class="fa fa-filter" aria-hidden="true"></i></button>
  <div>
   <button type="button"  (click)= requestClearFilter() *ngIf="requestFilter2 == 'show'" class="btn " style="width: 150px;border:#211e50;margin-left: 52rem;  width: 150px;
     border: 1px solid #6f09e8;
     color: #343a40;margin-bottom: 10px;" >Clear Filter <i style="color: #6f09e8;
 font-size: 20px;
 margin-left: 5px;" class="fa fa-filter" aria-hidden="true"></i></button>
 </div>
    <div class="table-responsive">
         <table  class="table table-striped table-bordered" *ngIf="showTable" datatable [dtOptions]="dtOptions"  style="width:100%;">

          <thead class="thead-dark">
              <tr>
                <th>Id</th>
              <th>First <br />Name</th>
              <th>Last <br />Name</th>
              <th>Phone <br />Number</th>
              <th>Email</th>
              <th>Message</th>
              <th>Enquired <br />on</th>
          </tr>
          </thead>
          <tbody>
          <tr *ngFor= "let list of getReqests">

           <td>{{list.id}}</td>
           <td>{{list.firstName}}</td>
           <td>{{list.lastName}}</td>
           <td>{{list.mobile.substr(0, 3) + '-' + list.mobile.substr(3, 3) + '-' + list.mobile.substr(6, 4)}}</td>
           <td>{{list.email}}</td>
           <td>{{list.message}}</td>
           <td>{{list.createdOn | date:'MM-dd-yyyy'}}</td>
          </tr>
          </tbody>
      </table>
     </div>
  </div>
  </div>
  </div>
  </div>

  <ngx-spinner bdColor="rgba(51, 51, 51, 0.8)" size="default" type="ball-spin-clockwise">
    <p style="color: white">Please Wait. </p>
    </ngx-spinner>
    <div class="modal fade bd-example-modal-lg5" id="demo-7" tabindex="-1" style="overflow:scroll;">
      <div class="modal-dialog modal-dialog-centered appointment_popup_main">
          <div class="modal-content">
              <div class="modal-header">
                  <h6 class="modal-title" id="exampleModalLabel-2">Filters</h6>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>

              <div class="modal-body">
                <h6><b>Date Range</b></h6>
               <div class="row">
                <div class="col-md-6 form-group">
                  <label class="form_row_heading">Start Date <span class="text-danger">*</span></label>
                   <input type="text" placeholder="MM/DD/YYYY" (change)="getStartDate($event.target.value)"
                   onfocus="(this.type='date' )"  id="date" bsDatepicker minDate="today" class="form-control wizard-required" formControlName="dropOffDate"  name="dropDate" #from>
                </div>

                  <div class="col-md-6 form-group">
                    <label class="form_row_heading">End Date <span class="text-danger">*</span></label>
                     <input type="text" placeholder="MM/DD/YYYY" (change)="getEndDate($event.target.value)"
                     onfocus="(this.type='date' )"  id="date" bsDatepicker minDate="today" class="form-control wizard-required" formControlName="dropOffDate"  name="dropDate" #to>


                    </div>
              </div>
               </div>
               <div class="modal-footer">
                <button type="button" class="btn btn-danger" data-dismiss="modal">Cancel</button>
               <button type="submit" class="btn btn-success"   [disabled]="(from.value.length === 0  || to.value.length === 0  )"  data-dismiss="modal"   (click)="getFilterdData()">Submit</button>
           </div>
          </div>
      </div>
    </div>
